import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'antd';
import Profile from '../profile/Profile_sec';
import MyApplications from './MyApplications';
import { AuthContext } from '../../context/context';
import SideNav from './SideNav';
import TopNav from './TopNav';
import Layout from 'antd/es/layout/layout';
import { Outlet,useNavigate } from 'react-router-dom';

const { Sider, Content } = Layout;
const Candidate = () => {
  const { login, authToken } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      login(token);
    }
  }, [login]);

  const [profile, setProfile] = useState(false);
  const toggleProfile = () => {
    setProfile((prev) => !prev);
  };

  const [application, setApplications] = useState(false);
  const toggleApplications = () => {
    setApplications((prev) => !prev);
  };

  return (
    <div className='no_overflow'>
    <TopNav></TopNav>
    <Layout style={{height:1000}}>
      <SideNav></SideNav>
      <Layout>
      <Content style={{ padding: '0 24px 24px' }}>
                  <Outlet />
              </Content>
      </Layout>
    </Layout>
    {/* <div>
      <h2>This is candidate dashboard</h2>

      {profile ? (
        <Profile onBack={toggleProfile} />
      ) : (
        <Button onClick={toggleProfile}>View Profile</Button>
      )}

      {application ? (
        <MyApplications onBack={toggleApplications} />
      ) : (
        <Button  onClick={() => navigate('/candidate/my_application')}>My Applications</Button>
      )}
    </div> */}
  //</div>
  
  );
};

export default Candidate;
