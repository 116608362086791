import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, Alert} from 'antd';
import { AuthContext } from '../context/context';
import './login.css';

const { Title, Paragraph } = Typography;

const apiurl = process.env.REACT_APP_BACKEND_URL;

export const LoginForm = ({ onToggle }) => {
  const [user, setUser] = useState({
    username: '',
    password: ''
  });

  const [verify, setVerify] = useState(false);
  const { login } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${apiurl}/api/login/`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: user.username,
          password: user.password,
        })
      });

      if (!response.ok) {
        const responseData = await response.json();
        if (response.status === 401) {
          throw new Error('Incorrect username or password');
        } else {
          throw new Error(responseData.detail || 'Failed to login');
        }
      }

      setError(null);
      const userData = await response.json();
      login(userData.token);
      sessionStorage.setItem('authToken', userData.token);

      if (userData.token) {
        if (userData.is_verified) {
          switch (userData.role) {
            case 'recruiter':
              navigate('/recruiter');
              break;
            case 'client':
              navigate('/client');
              break;
            case 'candidate':
              navigate('/candidate');
              break;
            case 'manager':
              navigate('/manager');
              break;
            default:
              console.log('Unknown role:', userData.role);
              break;
          }
        } else {
          setVerify(true);
          setError("Please verify your email first");
        }
      } else {
        navigate('/');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyEmail = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${apiurl}/api/verify_resend_email/`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: user.username,
        })
      });

      if (!response.ok) {
        throw new Error("Failed to send email for verification");
      }

      setError("Mail sent.. check it");
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container left_form">
      <Form
        name="login_form"
        onFinish={handleSubmit}
      >
        {error && <Alert message={error} type="error" showIcon />}
        <Title level={2} style={{ textAlign: 'center' }}>Login</Title>
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input
            name="username"
            placeholder="Username"
            value={user.username}
            onChange={handleInputChange}
            className="login-input"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password
            name="password"
            placeholder="Password"
            value={user.password}
            onChange={handleInputChange}
            className="login-input"
          />
        </Form.Item>

        <div >
          <Button type="link" onClick={()=>navigate('/forgot_password')} disabled={loading} style={{ display: 'flex',marginTop:-10, marginLeft:-10  }} className='signup-text'>
            Forgot password?
          </Button>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} style={{height: 40, marginTop:-20 }}block className="login-button">
            Login
          </Button>
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center', color:'black' }}>
          <Paragraph>Don't have an account?
          <Button type="link" onClick={onToggle} disabled={loading} className='signup-text'>
            Sign Up
          </Button>
          </Paragraph>
        </div>
        {verify && (
          <Button type="link" onClick={handleVerifyEmail} disabled={loading}>
            Verify Email
          </Button>
        )}
      </Form>
    </div>
  );
};

export default LoginForm;
