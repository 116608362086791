import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/context";
import { Link } from "react-router-dom";
import "./Client.css";
import LayoutComp from "./Layout";
import MyJobPosts from "./MyJobPosts";

const Client = () => {
	const { login } = useContext(AuthContext);

	useEffect(() => {
		const token = sessionStorage.getItem("authToken");
		if (token) {
			login(token);
		}
	}, [login]);

	return (
		<LayoutComp>
			<div className="client-dashboard">
				<div className="sec-1">
					<div>Review all Postings</div>
					<Link to="/client/new_job_post/terms_and_conditions">
						Add New Post
					</Link>
				</div>
				<div className="job-postings">
					<MyJobPosts />
				</div>
			</div>
		</LayoutComp>
	);
};

export default Client;
