import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Tooltip, Select, Alert, Typography } from "antd";
import {
	InfoCircleOutlined,
	UserOutlined,
	MailOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../context/context";
import "./signup.css";
const { Option } = Select;

const apiurl = process.env.REACT_APP_BACKEND_URL;

const SignupForm = ({ onToggle }) => {
	const [user, setUser] = useState({
		username: "",
		password: "",
		confirm_password: "",
		email: "",
		role: "",
	});

	const { login } = useContext(AuthContext);
	const navigate = useNavigate();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [emailVerified, setEmailVerified] = useState(false);
	const [roleSelected, setRoleSelected] = useState(false);
	const [clientLoading, setClientLoading] = useState(false);

	const isFormValid = () => {
		return (
			user.username &&
			user.password &&
			user.confirm_password &&
			user.email &&
			user.role
		);
	};
	const validateUsername = (_, value) => {
		if (!value || /^[a-zA-Z0-9_]+$/.test(value)) {
			return Promise.resolve();
		}
		return Promise.reject(
			new Error(
				"Username should contain only letters, numbers, and underscores."
			)
		);
	};

	const validateEmail = (_, value) => {
		if (!value || /\S+@\S+\.\S+/.test(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error("Please enter a valid email address."));
	};
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setUser((prevUser) => ({
			...prevUser,
			[name]: value,
		}));
	};

	const handleRoleSelect = (role) => {
		setUser((prevUser) => ({
			...prevUser,
			role: role,
		}));
		setRoleSelected(true);
	};

	const handleSubmit = async () => {
		setLoading(true);

		if (!user.role) {
			setError("Please select a role");
			setLoading(false);
			return;
		}

		if (user.password !== user.confirm_password) {
			setError("Passwords do not match");
			setLoading(false);
			return;
		}

		try {
			const response = await fetch(`${apiurl}/api/signup/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: user.username,
					password: user.password,
					email: user.email,
					role: user.role,
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to register user");
			}

			const userData = await response.json();
			setError(userData.error);
			login(userData.token);

			if (userData.is_verified) {
			}

			sessionStorage.setItem("authToken", userData.token);
			if (emailVerified) {
				setEmailVerified(true);
				switch (userData.role) {
					case "manager":
						navigate("/manager");
						break;
					case "recruiter":
						navigate("/recruiter");
						break;
					case "client":
						navigate("/client");
						break;
					case "candidate":
						navigate("/candidate");
						break;
					default:
            navigate("/");
						break;
				}
        navigate("/");
			}
		} catch (error) {
			setError("User with provided credentials already exists");
		} finally {
			setLoading(false);
		}
	};

	if (!roleSelected) {
		return (
			<div className="container-signup left_form">
				<Typography.Title
					level={1}
					style={{ textAlign: "center", marginBottom: "1rem" }}>
					Sign up as
				</Typography.Title>
				<div className="role_buttons">
					<Button
						type="default"
						onClick={() => {
							setClientLoading(true);
							navigate("/client/signup");
						}}>
						Client
					</Button>
					<Button type="default" onClick={() => handleRoleSelect("candidate")}>
						Job Seeker
					</Button>
					<div className="centered">
						<p>
							Already have an account?
							<Button
								type="text"
								onClick={onToggle}
								style={{
									width: 50,
									textDecoration: "none",
								}}>
								Login
							</Button>
						</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className=" left_form container-signup ">
			<div>
				<Form
					name="signup_form"
					onFinish={handleSubmit}
					autoComplete="off"
					initialValues={{
						username: "",
						password: "",
						confirm_password: "",
						email: "",
						role: "candidate",
					}}>
					<Typography.Title level={2} style={{ textAlign: "center" }}>
						{user.role.charAt(0).toUpperCase() + user.role.slice(1)}'s Signup
					</Typography.Title>

					{error && (
						<Alert
							message={error}
							type="error"
							showIcon
							style={{ marginBottom: "1rem" }}
						/>
					)}

					<Form.Item
						name="username"
						rules={[{ required: true, validator: validateUsername }]}
						validateTrigger="onBlur">
						<Input
							className="login-input"
							value={user.username}
							onChange={handleInputChange}
							name="username"
							placeholder="Enter your username"
							prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							suffix={
								<Tooltip title="Username should contain only letters, numbers, and underscores">
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
						/>
					</Form.Item>

					<Form.Item
						name="email"
						value={user.email}
						rules={[{ required: true, validator: validateEmail }]}
						validateTrigger="onBlur">
						<Input
							className="login-input"
							value={user.email}
							onChange={handleInputChange}
							name="email"
							placeholder="Enter your email"
							prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
						/>
					</Form.Item>

					<Form.Item
						name="password"
						rules={[
							{ required: true, message: "Please input your Password!" },
						]}>
						<Input.Password
							name="password"
							value={user.password}
							onChange={handleInputChange}
							placeholder="Password"
							className="login-input"
						/>
					</Form.Item>

					<Form.Item
						name="confirm_password"
						rules={[
							{ required: true, message: "Please confirm your Password!" },
						]}>
						<Input.Password
							name="confirm_password"
							value={user.confirm_password}
							onChange={handleInputChange}
							placeholder="Confirm Password"
							className="login-input"
						/>
					</Form.Item>

					<Form.Item
						name="role"
						rules={[{ required: true, message: "Please select Your role!" }]}>
						<Input
							name="role"
							value={user.role}
							onChange={handleInputChange}
							placeholder="Role"
							className="login-input"
							disabled
						/>
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							style={{ width: 300 }}>
							Signup
						</Button>
					</Form.Item>

					<Typography textAlign="center">
						Already have an account?{" "}
						<Button type="link" onClick={onToggle} style={{ color: "black" }}>
							Login
						</Button>
					</Typography>
				</Form>
			</div>
		</div>
	);
};

export { SignupForm };
