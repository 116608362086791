import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/context';
import { useNavigate } from 'react-router-dom';
import SideNav from '../client/SideNav';
import { Typography, Alert, Row, Col, Card, Form, Input, Button, Spin, Result } from 'antd';
import { UserOutlined, MailOutlined, IdcardOutlined } from '@ant-design/icons';
import './Profile_sec.css';
import TopNav from '../client/TopNav';

const apiurl = process.env.REACT_APP_BACKEND_URL;

const Profile = ({ onBack }) => {
  const navigate = useNavigate();
  const { authToken, login, logout } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState({
    designation: null,
    contact_number: null,
    website_url: null,
    gst: null,
    company_pan: null,
    company_address: null,
    state: null,
    city: null
  });
  const [user, setUser] = useState({
    username: null,
    email: null,
    role: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { Title } = Typography;

  const handleLogout = () => {
    sessionStorage.removeItem('authToken');
    logout();
    navigate('/');
  };

  const toggleProfile = () => {
    navigate('/edit_profile/');
  };

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      login(token);
    }
  }, []);

  useEffect(() => {
    if (!authToken) {
      navigate('/login');
    } else {
      const fetchUserDetails = async () => {
        try {
          const response = await fetch(`${apiurl}/api/user_view/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          });

          if (!response.ok) {
            throw new Error('Failed to fetch user details');
          }

          const data = await response.json();
          setUser(data.data);
          setUserDetails(data.role_data);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchUserDetails();
    }
  }, [authToken]);

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Error"
        subTitle={`Error: ${error}`}
        extra={[
          <Button key="back" onClick={onBack}>
            Back
          </Button>,
        ]}
      />
    );
  }

  if (!user || !user.username) {
    return (
      <Result
        status="warning"
        title="No user details found"
        extra={[
          <Button key="back" onClick={() => navigate(-1)}>
            Back
          </Button>,
        ]}
      />
    );
  }

  return (
    <div className='no_overflow'>
      <TopNav />
      <div className="flex">
        <SideNav />
        <div className='data-overflow items-center'>
          <img src="../src/media/modi.png" alt="profile img" className='img-style' />
          <Card style={{ width: '60vw', margin: '20px auto', padding: '20px' }}>
            <Form layout="vertical">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item label="Name">
                    <Input
                      prefix={<UserOutlined />}
                      value={user.username}
                      readOnly
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Designation">
                    <Input
                      prefix={<IdcardOutlined />}
                      value={userDetails.designation}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="Email">
                    <Input
                      prefix={<MailOutlined />}
                      value={user.email}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="Contact Number">
                    <Input
                      value={userDetails.contact_number}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="GST">
                    <Input
                      value={userDetails.gst}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="Pan Number">
                    <Input
                      value={userDetails.company_pan}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="Website URL">
                    <Input
                      value={userDetails.website_url}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="Company address">
                    <Input
                      value={userDetails.company_address}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item label="City">
                    <Input
                      value={userDetails.city}
                      readOnly
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="State">
                    <Input
                      value={userDetails.state}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="Role">
                    <Input
                      prefix={<IdcardOutlined />}
                      value={user.role}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <div className='button-container'>
            <Button className="button button-secondary" onClick={handleLogout}>
              Logout
            </Button>
            <Button className='button button-secondary' onClick={toggleProfile}>Edit Profile</Button>
            <Button className="button button-secondary" onClick={()=>navigate(-1)}>
              Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
