import React, { useEffect, useState } from "react";
import { Table, Button, Select, Form, Tabs, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./Recruiters.css";
import Main from "./Layout";
import AddRecruiters from "./AddRecruiters";
import Applications from "./Applications";

const { Option } = Select;
const { TabPane } = Tabs;

const apiurl = process.env.REACT_APP_BACKEND_URL;

const Recruiters = () => {
	const [jobList, setJobList] = useState([]);
	const [customData1, setCustomData1] = useState([]);
	const [customData2, setCustomData2] = useState([]);
	const navigate = useNavigate();
	const [recruiters, setRecruiters] = useState([]);
	const [selectedRecruiter, setSelectedRecruiter] = useState({});
	const [isVisible, setIsVisible] = useState(false);

	const authToken = sessionStorage.getItem("authToken");

	useEffect(() => {
		fetchJobPosts();
		fetchCustomData1();
		fetchCustomData2();
	}, [authToken]);

	const fetchJobPosts = () => {
		fetch(`${apiurl}/api/manager/recruiter_summary/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setJobList(data.data);
			})
			.catch((error) => console.error("Error fetching job posts:", error));
	};

	const fetchCustomData1 = () => {
		fetch(`${apiurl}/api/manager/work_to_recruiter/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setCustomData1(data.data);
				setRecruiters(data.recruiter_data);
			})
			.catch((error) => console.error("Error fetching custom data 1:", error));
	};

	const fetchCustomData2 = () => {
		fetch(`${apiurl}/api/manager/recruiter_summary/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setCustomData2(data.data);
			})
			.catch((error) => console.error("Error fetching custom data 2:", error));
	};

	const renderDefaultTable = () => {
		return (
			<Table
				columns={defaultColumns}
				dataSource={jobList}
				rowKey="id"
				className="custom-table"
				pagination={false}
			/>
		);
	};

	const renderRecTable = () => {
		return (
			<Table
				columns={recColumns}
				dataSource={recruiters}
				rowKey="id"
				className="custom-table"
				pagination={false}
			/>
		);
	};

	const renderCustomTable1 = () => {
		return (
			<Table
				columns={customColumns1}
				dataSource={customData1}
				rowKey="id"
				className="custom-table"
				pagination={false}
			/>
		);
	};

	const renderCustomTable2 = () => {
		return (
			<Table
				columns={customColumns2}
				dataSource={customData2}
				rowKey="id"
				className="custom-table"
				pagination={false}
			/>
		);
	};

	const handleSave = (jobId) => {
		const recruiterId = selectedRecruiter[jobId];
		if (recruiterId) {
			fetch(`${apiurl}/api/manager/work_to_recruiter/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${authToken}`,
				},
				body: JSON.stringify({ jobId, recruiterId }),
			})
				.then((response) => response.json())
				.then((data) => {
					fetchJobPosts();
				})
				.catch((error) => console.error("Error assigning recruiter:", error));
		}
	};

	const handleRecruiterChange = (value, jobId) => {
		setSelectedRecruiter((prev) => ({ ...prev, [jobId]: value }));
	};

	const renderRecruiterName = (recruiterName, record) => {
		if (recruiterName) {
			return recruiterName;
		} else {
			return (
				<Form onFinish={() => handleSave(record.id)}>
					<Select
						placeholder="Select Recruiter"
						onChange={(value) => handleRecruiterChange(value, record.id)}
						style={{ width: "100%" }}>
						{recruiters &&
							recruiters.length >= 1 &&
							recruiters.map((recruiter) => (
								<Option key={recruiter.id} value={recruiter.id}>
									{recruiter.username}
								</Option>
							))}
					</Select>
					<Button type="primary" htmlType="submit">
						Save
					</Button>
				</Form>
			);
		}
	};

	const recColumns = [
		{
			title: "Recruiter Name",
			dataIndex: "username",
			key: "username",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
	];

	const defaultColumns = [
		{
			title: "Recruiter",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Client",
			dataIndex: "client_name",
			key: "client_name",
		},
		{
			title: "Role",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "No. of positions",
			dataIndex: "num_of_positions",
			key: "num_of_positions",
		},
		{
			title: "No. of resumes shared",
			dataIndex: "resumes_sent",
			key: "resumes_sent",
		},
		{
			title: "No. of Resources selected",
			dataIndex: "resumes_accepted",
			key: "resumes_accepted",
		},
		{
			title: "Job Status",
			dataIndex: "status",
			key: "status",
		},
	];

	const customColumns1 = [
		{
			title: "Client Name",
			dataIndex: "username",
			key: "username",
		},
		{
			title: "Role",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "Primary Skills",
			dataIndex: "primary_skills",
			key: "primary_skills",
		},
		{
			title: "Secondary Skills",
			dataIndex: "secondary_skills",
			key: "secondary_skills",
		},
		{
			title: "Allocate Recruiter",
			dataIndex: "recruiter_name",
			key: "recruiter_name",
			render: renderRecruiterName,
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Link to={`/job_post_details/${record.id}`}>View</Link>
			),
		},
	];

	const customColumns2 = [
		{
			title: "Client Name",
			dataIndex: "client_name",
			key: "client_name",
		},
		{
			title: "Role",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "No. of Positions",
			dataIndex: "num_of_positions",
			key: "num_of_positions",
		},
		{
			title: "Latest Status",
			dataIndex: "latest_status",
			key: "latest_status",
		},
		{
			title: "Deadline",
			dataIndex: "deadline",
			key: "deadline",
		},
		{
			title: "Issue",
			dataIndex: "issue",
			key: "issue",
		},
	];

	return (
		<Main>
			<div>
				<Tabs defaultActiveKey="1">
					<TabPane tab="Recruiter's" key="1">
						<>
							<div className="add-rec-btn">
								<Button onClick={() => setIsVisible(true)}>
									Add Recruiter
								</Button>
								<br></br>
							</div>
							{renderRecTable()}
						</>
					</TabPane>
					<TabPane tab="Recruiter's Summary" key="2">
						{renderDefaultTable()}
					</TabPane>
					<TabPane tab="Work Allocated to Recruiter" key="3">
						{renderCustomTable1()}
					</TabPane>
					<TabPane tab="Challenges of Recruiter" key="4">
						{renderCustomTable2()}
					</TabPane>
					<TabPane tab="Applications Sent By Recruiters" key="5">
						<Applications />
					</TabPane>
				</Tabs>
				<Modal
					open={isVisible}
					onCancel={() => setIsVisible(false)}
					footer={null}>
					<AddRecruiters />
				</Modal>
			</div>
		</Main>
	);
};

export default Recruiters;
