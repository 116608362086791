import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	ResumeColumn,
	JobIdColumn,
	CandidateNameColumn,
	ActionsColumn,
	StatusColumn,
	PrimarySkillsColumn,
	SecondarySkillsColumn,
} from "./ResumeComponents";
import Main from "./Layout";
import "./Resume.css";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const Resumes = () => {
	const { id } = useParams();
	const [data, setData] = useState([]);
	const [error, setError] = useState("");
	const [feedback, setFeedback] = useState("");
	const [feedbackVisible, setFeedbackVisible] = useState(false);
	const [currentApplicationId, setCurrentApplicationId] = useState(null);
	const [feedbackAction, setFeedbackAction] = useState("");
	const [primarySkills, setPrimarySkills] = useState([]);
	const [secondarySkills, setSecondarySkills] = useState([]);
	const [job, setJob] = useState(null);
	const [skillsetKeys, setSkillsetKeys] = useState([]);
	const [loading, SetLoading] = useState(false);
	const authToken = sessionStorage.getItem("authToken");

	useEffect(() => {
		if (authToken) {
			fetch(`${apiurl}/api/client/job_resume/${id}/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					setData(data.data);
					setJob(data.job_data);
				})
				.catch((error) => console.error("Error fetching job posts:", error));
		}
	}, [authToken, id]);

	useEffect(() => {
		if (job) {
			const primarySkillsArray = job.primary_skills
				.split(",")
				.map((skill) => skill.trim());
			const secondarySkillsArray = job.secondary_skills
				.split(",")
				.map((skill) => skill.trim());
			setPrimarySkills(primarySkillsArray);
			setSecondarySkills(secondarySkillsArray);
			setSkillsetKeys(Object.keys(data[0]?.skillset || {}));
		}
	}, [job, data]);

	const handleAction = async (id, reply) => {
		if (reply === "Reject" || reply === "Shortlisted") {
			setFeedbackVisible(true);
			setCurrentApplicationId(id);
			setFeedbackAction(reply);
			return;
		}

		try {
			const response = await fetch(
				`${apiurl}/api/client/candidate/save_response/${id}/`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${authToken}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ response: reply, feedback: feedback }),
				}
			);

			if (!response.ok) {
				const dataa = await response.json();
				alert(dataa.error);
			} else {
				const dataa = await response.json();
				alert(dataa.success);
				setData(dataa.data);
			}
		} catch (e) {
			console.log(e);
			setError(e);
		}
	};

	const handleFeedbackSubmit = async (e) => {
		e.preventDefault();
		SetLoading(true);
		try {
			const response = await fetch(
				`${apiurl}/api/client/candidate/save_response/${currentApplicationId}/`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${authToken}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						response: feedbackAction,
						feedback: feedback,
					}),
				}
			);

			SetLoading(false);
			if (!response.ok) {
				const dataa = await response.json();
				console.log(dataa);
				alert(dataa.error);
			} else {
				const dataa = await response.json();
				alert(dataa.success);
				setData(dataa.data);
				setFeedbackVisible(false);
				setFeedback("");
				window.location.reload();
			}
		} catch (e) {
			setError(e);
		}
	};

	if (!job) return null;

	return (
		<Main>
			<div className="resumes-main">
				<div className="jobInfo">
					<h1>
						{job.job_title}({id})
					</h1>
					<p>
						Job Description: {job.job_description}
						<br />
						CTC: {job.ctc}
					</p>
				</div>

				<div className="applicationTable">
					<div className="row">
						<div className="cell">Field</div>
						{data &&
							data.map((application, index) => (
								<div key={index} className="cell">
									Application {index + 1}
								</div>
							))}
					</div>

					<div className="row">
						<div className="cell">Candidate Name</div>
						{data &&
							data.map((application, index) => (
								<CandidateNameColumn key={index} application={application} />
							))}
					</div>

					<div className="row">
						<div className="cell">Job ID</div>
						{data &&
							data.map((application, index) => (
								<JobIdColumn key={index} application={application} />
							))}
					</div>

					<div className="row">
						<div className="cell">Primary Skills and Experience</div>
					</div>

					{primarySkills.map((skill, index) => (
						<div className="row" key={index}>
							<div className="cell">{skill}</div>
							{data &&
								data.map((application, idx) => (
									<PrimarySkillsColumn
										key={idx}
										application={application}
										skill={skill}
									/>
								))}
						</div>
					))}

					<div className="row">
						<div className="cell">Secondary Skills and Experience</div>
					</div>

					{secondarySkills.map((skill, index) => (
						<div className="row" key={index}>
							<div className="cell">{skill}</div>
							{data &&
								data.map((application, idx) => (
									<SecondarySkillsColumn
										key={idx}
										application={application}
										skill={skill}
									/>
								))}
						</div>
					))}

					<div className="row">
						<div className="cell">Resume</div>
						{data &&
							data.map((application, index) => (
								<ResumeColumn key={index} application={application} />
							))}
					</div>

					<div className="row">
						<div className="cell">Status</div>
						{data &&
							data.map((application, index) => (
								<StatusColumn key={index} application={application} />
							))}
					</div>

					<div className="row">
						<div className="cell">Actions</div>
						{data &&
							data.map((application, index) => (
								<ActionsColumn
									key={index}
									application={application}
									handleAction={handleAction}
									feedbackVisible={feedbackVisible}
									currentApplicationId={currentApplicationId}
									feedback={feedback}
									setFeedback={setFeedback}
									handleFeedbackSubmit={handleFeedbackSubmit}
								/>
							))}
					</div>
				</div>

				{feedbackVisible && (
					<form onSubmit={handleFeedbackSubmit}>
						<h2>Provide Feedback for {feedbackAction}</h2>
						<textarea
							value={feedback}
							onChange={(e) => setFeedback(e.target.value)}
							required
						/>
						<button type="submit">Submit Feedback</button>
					</form>
				)}
			</div>
		</Main>
	);
};

export default Resumes;
