import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/context';
import './Editjob.css';
import Applications from './Applications';
import TopNav from './TopNav';
import SideNav from './SideNav';
const apiurl = process.env.REACT_APP_BACKEND_URL;
const Editjob = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { login, authToken } = useContext(AuthContext);
    const [message, setMessage] = useState('');
    const [interviewersEdited, setInterviewersEdited] = useState(false);

    useEffect(() => {
        const token = sessionStorage.getItem('authToken');
        if (token) {
            login(token);
        }
    }, [login]);

    const [job, setJob] = useState({
        job_id: id,
        job_title: '',
        job_description: '',
        primary_skills: '',
        secondary_skills: '',
        years_of_experience: 0,
        ctc: '',
        rounds_of_interview: 0,
        interviewers: [],
        interviewer_emails: [],
        job_location: '',
        job_type: '',
        job_level: '',
        qualifications: '',
        timings: '',
        other_benefits: '',
        working_days_per_week: 5,
        interview_process: '',
        decision_maker: '',
        bond: '',
        rotational_shift: false,
        is_approved: true,
        is_assigned: null,
    });

    useEffect(() => {
        if (authToken) {
            fetch(`${apiurl}/api/particular_job/${id}/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    setJob(data.data);
                    console.log(data)
                    if (data.interviewers_edited.length == 0) {
                        setInterviewersEdited(false)
                        console.log("entered here")
                    }
                    else {
                        setInterviewersEdited(true)
                    }
                })
                .catch(error => console.error('Error fetching job details:', error));
        }
    }, [authToken, id]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name.startsWith('interviewers-')) {
            const index = parseInt(name.split('-')[1], 10);
            const updatedInterviewers = [...job.interviewers];
            updatedInterviewers[index] = value;
            setJob({
                ...job,
                interviewers: updatedInterviewers
            });
        } else if (name.startsWith('interviewer_emails-')) {
            const index = parseInt(name.split('-')[1], 10);
            const updatedInterviewerEmails = [...job.interviewer_emails];
            updatedInterviewerEmails[index] = value;
            setJob({
                ...job,
                interviewer_emails: updatedInterviewerEmails
            });
        } else if (name === 'rounds_of_interview') {
            const rounds = parseInt(value, 10) || 0;
            setJob(prevState => ({
                ...prevState,
                rounds_of_interview: rounds,
                interviewers: Array(rounds).fill(''),
                interviewer_emails: Array(rounds).fill(''),
            }));
        } else {
            setJob(prevJob => ({
                ...prevJob,
                [name]: value
            }));
        }
    };

    const handleSubmit = () => {
        const postData = {
            ...job,
            interviewers: job.interviewers ? job.interviewers.filter(item => item !== '') : [],
            interviewer_emails: job.interviewer_emails ? job.interviewer_emails.filter(item => item !== '') : [], // Remove empty strings
        };
        console.log(postData);
        fetch(`${apiurl}/api/edit_particular_job/${id}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.ok) {
                    setMessage("Approval request sent to the client successfully");
                } else {
                    throw new Error('Failed to update job details');
                }
            })
            .catch(error => console.error('Error updating job details:', error));
    };

    const handleEditRounds = () => {
        navigate(`/manager/edit_rounds/${id}`);
    }
    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="no-overflow">
            <TopNav></TopNav>
            <div className="flex">
                <SideNav></SideNav>
                <div className="job-details-container data-overflow">
                    <div className="job-details">
                        <h1>Edit Job Details</h1>
                        <hr />
                        {message && <p className="message">{message}</p>}
                        <div className="form-group">
                            <label htmlFor="job_title">Job Title</label>
                            <input
                                type="text"
                                name="job_title"
                                value={job.job_title}
                                onChange={handleInputChange}
                                placeholder="Job Title"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="job_description">Job Description</label>
                            <input
                                type="text"
                                name="job_description"
                                value={job.job_description}
                                onChange={handleInputChange}
                                placeholder="Job Description"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="job_department">Job Department</label>
                            <input
                                type="text"
                                name="job_department"
                                value={job.job_department}
                                onChange={handleInputChange}
                                placeholder="Job Department"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="primary_skills">Primary Skills</label>
                            <input
                                type="text"
                                name="primary_skills"
                                value={job.primary_skills}
                                onChange={handleInputChange}
                                placeholder="Primary Skills"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="secondary_skills">Secondary Skills</label>
                            <input
                                type="text"
                                name="secondary_skills"
                                value={job.secondary_skills}
                                onChange={handleInputChange}
                                placeholder="Secondary Skills"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="years_of_experience">Years of Experience</label>
                            <input
                                type="number"
                                name="years_of_experience"
                                value={job.years_of_experience}
                                onChange={handleInputChange}
                                placeholder="Years of Experience"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="ctc">CTC</label>
                            <input
                                type="text"
                                name="ctc"
                                value={job.ctc}
                                onChange={handleInputChange}
                                placeholder="CTC"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="rounds_of_interview">Rounds of Interview</label>
                            <input
                                type="number"
                                name="rounds_of_interview"
                                value={job.rounds_of_interview}
                                onChange={handleInputChange}
                                placeholder="Rounds of Interview"
                            />
                        </div>
                        {job.interviewers && job.interviewers.map((name, index) => (
                            <div className="form-group" key={index}>
                                <label htmlFor={`interviewers-${index}`}>Interviewer {index + 1}</label>
                                <input
                                    type="text"
                                    name={`interviewers-${index}`}
                                    value={name}
                                    onChange={handleInputChange}
                                    placeholder={`Interviewer ${index + 1}`}
                                />
                            </div>
                        ))}
                        {job.interviewer_emails && job.interviewer_emails.map((email, index) => (
                            <div className="form-group" key={index}>
                                <label htmlFor={`interviewer_emails-${index}`}>Interviewer Email {index + 1}</label>
                                <input
                                    type="text"
                                    name={`interviewer_emails-${index}`}
                                    value={email}
                                    onChange={handleInputChange}
                                    placeholder={`Interviewer Email ${index + 1}`}
                                />
                            </div>
                        ))}
                        <div className="form-group">
                            <label htmlFor="job_location">Job Location</label>
                            <input
                                type="text"
                                name="job_location"
                                value={job.job_location}
                                onChange={handleInputChange}
                                placeholder="Job Location"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="job_type">Job Type</label>
                            <input
                                type="text"
                                name="job_type"
                                value={job.job_type}
                                onChange={handleInputChange}
                                placeholder="Job Type"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="job_level">Job Level</label>
                            <input
                                type="text"
                                name="job_level"
                                value={job.job_level}
                                onChange={handleInputChange}
                                placeholder="Job Level"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="qualifications">Qualifications</label>
                            <input
                                type="text"
                                name="qualifications"
                                value={job.qualifications}
                                onChange={handleInputChange}
                                placeholder="Qualifications"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="timings">Timings</label>
                            <input
                                type="text"
                                name="timings"
                                value={job.timings}
                                onChange={handleInputChange}
                                placeholder="Timings"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="other_benefits">Other Benefits</label>
                            <input
                                type="text"
                                name="other_benefits"
                                value={job.other_benefits}
                                onChange={handleInputChange}
                                placeholder="Other Benefits"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="working_days_per_week">Working Days per Week</label>
                            <input
                                type="number"
                                name="working_days_per_week"
                                value={job.working_days_per_week}
                                onChange={handleInputChange}
                                placeholder="Working Days per Week"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="decision_maker">Decision Maker</label>
                            <input
                                type="text"
                                name="decision_maker"
                                value={job.decision_maker}
                                onChange={handleInputChange}
                                placeholder="Decision Maker"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="bond">Bond</label>
                            <input
                                type="text"
                                name="bond"
                                value={job.bond}
                                onChange={handleInputChange}
                                placeholder="Bond"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="rotational_shift">
                                Rotational Shift
                                <input
                                    type="checkbox"
                                    name="rotational_shift"
                                    checked={job.rotational_shift}
                                    onChange={(e) => handleInputChange({
                                        target: {
                                            name: e.target.name,
                                            value: e.target.checked
                                        }
                                    })}
                                />
                            </label>
                        </div>
                        <div>
                            <button disabled={interviewersEdited} onClick={handleEditRounds}>Edit Rounds</button>
                            {interviewersEdited && <p>Interviewers Edit Request Already Sent</p>}
                        </div>
                        <div className="form-group buttons">
                            <button onClick={handleSubmit}>Submit</button>
                            <button onClick={handleBack}>Go Back</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Editjob;
