import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import "./ParticularApplication.css";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const ParticularApplication = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const authToken = sessionStorage.getItem("authToken");

	const [applicationDetails, setApplicationDetails] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (authToken) {
			fetch(`${apiurl}/api/particular_application/${id}/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					setApplicationDetails(data.data);
					setLoading(false);
				})
				.catch((error) => {
					console.error("Error fetching application details:", error);
					setLoading(false);
				});
		}
	}, [id, authToken]);

	const renderSkillset = (skillset) => {
		return Object.keys(skillset).map((skillhead) =>
			Object.keys(skillset[skillhead]).map((skill) => (
				<p key={skill}>
					<strong>{skill}:</strong> {skillset[skillhead][skill]} years
				</p>
			))
		);
	};

	const onBack = () => {
		navigate(-1);
	};

	if (loading) {
		return <div className="pa-loading">Loading...</div>;
	}

	if (!applicationDetails) {
		return <div className="pa-no-details">No application details found.</div>;
	}

	return (
		<div className="pa-application-details">
			<h1 className="pa-heading">Application Details</h1>
			<div className="pa-detail-card">
				<div className="pa-detail-card-header">Job Information</div>
				<div className="pa-detail-card-content">
					<div className="pa-detail-row">
						<div className="pa-detail-item">
							<strong>Job ID:</strong> {applicationDetails.job_id}
						</div>
						<div className="pa-detail-item">
							<strong>Candidate Name:</strong>{" "}
							{applicationDetails.candidate_name}
						</div>
					</div>
					<div className="pa-detail-row">
						<div className="pa-detail-item">
							<strong>Candidate Email:</strong>{" "}
							{applicationDetails.candidate_email}
						</div>
						<div className="pa-detail-item">
							<strong>Contact Number:</strong>{" "}
							{applicationDetails.contact_number}
						</div>
					</div>
					{applicationDetails.alternate_contact_number && (
						<div className="pa-detail-row">
							<div className="pa-detail-item">
								<strong>Alternate Contact Number:</strong>{" "}
								{applicationDetails.alternate_contact_number}
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="pa-detail-card">
				<div className="pa-detail-card-header">Additional Information</div>
				<div className="pa-detail-card-content">
					<div className="pa-detail-row">
						<div className="pa-detail-item">
							<strong>Current Organisation:</strong>{" "}
							{applicationDetails.current_organisation}
						</div>
					</div>
					<div className="pa-detail-row">
						<div className="pa-detail-item">
							<strong>Current Job Location:</strong>{" "}
							{applicationDetails.current_job_location}
						</div>
						<div className="pa-detail-item">
							<strong>Current Job Type:</strong>{" "}
							{applicationDetails.current_job_type}
						</div>
					</div>
					<div className="pa-detail-row">
						<div className="pa-detail-item">
							<strong>Date of Birth:</strong> {applicationDetails.date_of_birth}
						</div>
						<div className="pa-detail-item">
							<strong>Total Years of Experience:</strong>{" "}
							{applicationDetails.total_years_of_experience} years
						</div>
					</div>
					<div className="pa-detail-row">
						<div className="pa-detail-item">
							<strong>Job Status:</strong> {applicationDetails.job_status}
						</div>
						<div className="pa-detail-item">
							<strong>Current CTC:</strong> {applicationDetails.current_ctc}
						</div>
					</div>
					<div className="pa-detail-row">
						<div className="pa-detail-item">
							<strong>Expected CTC:</strong> {applicationDetails.expected_ctc}
						</div>
						<div className="pa-detail-item">
							<strong>Notice Period:</strong> {applicationDetails.notice_period}
						</div>
					</div>
					<div className="pa-detail-row">
						<div className="pa-detail-item">
							<strong>Joining Days Required:</strong>{" "}
							{applicationDetails.joining_days_required}
						</div>
						<div className="pa-detail-item">
							<strong>Highest Qualification:</strong>{" "}
							{applicationDetails.highest_qualification}
						</div>
					</div>
					<div className="pa-detail-item">
						<strong>Application Status:</strong> {applicationDetails.status}
					</div>
				</div>
			</div>
			<div className="pa-detail-card">
				<div className="pa-detail-card-header">Skill Set</div>
				<div className="pa-detail-card-content">
					<div className="pa-detail-item">
						{applicationDetails.skillset &&
							renderSkillset(applicationDetails.skillset)}
					</div>
				</div>
			</div>

			<div className="pa-detail-card">
				<div className="pa-detail-card-header">Resume</div>
				<div className="pa-detail-card-content">
					<a
						href={`${apiurl}${applicationDetails.resume}`}
						target="_blank"
						rel="noopener noreferrer"
						className="pa-resume-link">
						View Resume
					</a>
				</div>
			</div>
			<Button onClick={onBack}>Back</Button>
		</div>
	);
};

export default ParticularApplication;
