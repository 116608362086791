import React, { useState, useEffect } from "react";
import { Select, Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import Main from "./Layout";
const apiurl = process.env.REACT_APP_BACKEND_URL;

const Applications = () => {
	const navigate = useNavigate();
	const authToken = sessionStorage.getItem("authToken");

	const [applicationList, setApplicationList] = useState([]);
	const [newList, setNewList] = useState([]);
	const [filter, setFilter] = useState("all");

	useEffect(() => {
		fetchApplications();
	}, [authToken]);

	const fetchApplications = () => {
		console.log(`${apiurl}`);
		fetch(`${apiurl}/api/recruiter/applications/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setApplicationList(data.data);
				setNewList(data.data);
			})
			.catch((error) => console.error(error));
	};

	const viewApplication = (id) => {
		navigate(`/application/${id}`);
	};

	const handleFilters = (value) => {
		setFilter(value);
		let filteredList;
		switch (value) {
			case "reject":
				filteredList = applicationList.filter(
					(application) => application.status === "rejected"
				);
				break;
			case "accepted":
				filteredList = applicationList.filter(
					(application) => application.status === "accepted"
				);
				break;
			case "round1":
				filteredList = applicationList.filter(
					(application) => application.status === "round1"
				);
				break;
			case "round2":
				filteredList = applicationList.filter(
					(application) => application.status === "round2"
				);
				break;
			case "round3":
				filteredList = applicationList.filter(
					(application) => application.status === "round3"
				);
				break;
			case "hold":
				filteredList = applicationList.filter(
					(application) => application.status === "hold"
				);
				break;
			case "pending":
				filteredList = applicationList.filter(
					(application) => application.status === "pending"
				);
				break;
			case "shortlisted":
				filteredList = applicationList.filter(
					(application) => application.status === "shortlisted"
				);
				break;
			case "all":
			default:
				filteredList = applicationList;
				break;
		}
		setNewList(filteredList);
	};

	const columns = [
		{
			title: "Job Id",
			dataIndex: "job_id",
			key: "job_id",
		},
		{
			title: "Application Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Recruiter Name",
			dataIndex: "sender",
			key: "sender",
		},
		{
			title: "Resume Status",
			dataIndex: "is_viewed",
			key: "is_viewed",
			render: (is_viewed) => (is_viewed ? "Viewed" : "Not Viewed"),
		},
		{
			title: "Candidate Name",
			dataIndex: "candidate_name",
			key: "candidate_name",
		},
		{
			title: "Application Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "View Application",
			key: "action",
			render: (text, record) => (
				<Button onClick={() => viewApplication(record.id)}>
					View Complete Application
				</Button>
			),
		},
	];

	return (
		<>
			<div className="application-status-main">
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}>
					<Select
						defaultValue="all"
						style={{ width: 120, marginRight: 16 }}
						onChange={handleFilters}>
						<Select.Option value="all">All</Select.Option>
						<Select.Option value="reject">Rejected</Select.Option>
						<Select.Option value="accepted">Accepted</Select.Option>
						<Select.Option value="hold">On Hold</Select.Option>
						<Select.Option value="pending">Pending list</Select.Option>
						<Select.Option value="shortlisted">Shortlisted</Select.Option>
					</Select>
				</div>
				<Table
					className="applications-table"
					dataSource={newList}
					columns={columns}
					rowKey="id"
					pagination={false}
				/>
			</div>
		</>
	);
};

export default Applications;
