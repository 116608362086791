import React from 'react'; 
import { useContext, useEffect, useState } from 'react';
// import { AuthContext } from '../../context/context';
import { Outlet, useNavigate } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import { AuthContext } from '../../context/context';
import './SideNav.css'
import {DatabaseOutlined,MailOutlined,LogoutOutlined,CalendarOutlined, UsergroupAddOutlined,AppstoreOutlined,PlusCircleOutlined, RiseOutlined, CommentOutlined, EyeOutlined, FileTextOutlined, PieChartOutlined, HomeOutlined } from '@ant-design/icons'

const { Sider, Content } = Layout;

const SideNav = () => {
  const navigate = useNavigate();
  const {logout,authToken} = useContext(AuthContext);
  const handleLogout=()=>{
     logout();
     sessionStorage.removeItem('authToken');
     navigate('/')
  }

  return (
    <Sider width={200} className="site-layout-background">
      <Menu
        mode="inline"
        defaultSelectedKeys={['profile']}
        style={{ height: '100%', borderRight: 0 }}
      >
         <Menu.Item key="dashboard" onClick={() => navigate('/candidate/')} >
          <AppstoreOutlined  style={{ marginRight: '10px' }} />  Dashboard
        </Menu.Item>   
        <Menu.Item key="Jobdetails" onClick={() => navigate('/candidate/')}>
          <PieChartOutlined style={{ marginRight: '10px' }} />  Job details
        </Menu.Item>
        <Menu.Item key="selfassessment" onClick={() => navigate('/candidate/')}>
          <UsergroupAddOutlined style={{ marginRight: '10px' }} />  Self Assessment
        </Menu.Item>    
        <Menu.Item key="interview" onClick={() => navigate('/candidate/')}>
          <CalendarOutlined style={{ marginRight: '10px' }} />  Interviews
        </Menu.Item>
        <Menu.Item key="documents" onClick={() => navigate('/candidate/')}>
          <MailOutlined style={{ marginRight: '10px' }} /> Documents
        </Menu.Item>
        <Menu.Item key="applications" onClick={() => navigate('/candidate/my_application')}>
          <DatabaseOutlined style={{ marginRight: '10px' }} /> Applications
        </Menu.Item>
       
        <Menu.Item key="logout" onClick={() => handleLogout()}>
          <LogoutOutlined style={{ marginRight: '10px' }} />  Logout
        </Menu.Item>
      </Menu>
    </Sider>
    // <Sider width={200} className="site-layout-background">
    //     <Menu
    //         mode="inline"
    //         defaultSelectedKeys={['profile']}
    //         style={{ height: '100%', borderRight: 0 }}
    //     >
    //         <Menu.Item key="myJobPosts" onClick={() => navigate('/client/my_job_posts')}>
    //             See My Jobs
    //         </Menu.Item>
    //         <Menu.Item key="resume" onClick={() => navigate('/client/received_data')}>
    //             Received Data
    //         </Menu.Item>
    //         <Menu.Item key="addJobPost" onClick={() => navigate('/client/new_job_post/terms_and_conditions')}>
    //             Add Job Post
    //         </Menu.Item>
    //         <Menu.Item key="updates" onClick={() => navigate('/client/updates')}>
    //             See Updates by manager
    //         </Menu.Item>
    //         <Menu.Item key="myResponses" onClick={() => navigate('/client/my_responses')}>
    //             My Responses
    //         </Menu.Item>
    //         <Menu.Item key="promoteCandidates" onClick={() => navigate('/client/promote_candidates')}>
    //             Promote Candidates
    //         </Menu.Item>
    //     </Menu>
    // </Sider>

  );
}

export default SideNav

