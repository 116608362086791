import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const MyTask = () => {
	const authToken = sessionStorage.getItem("authToken");
	const [job, setJob] = useState([]);
	useEffect(() => {
		fetch(`${apiurl}/api/recruiterdash/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setJob(data.data);
			})
			.catch((error) => console.error("Error fetching job posts:", error));
	}, [authToken]);

	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Client",
			dataIndex: "client_name",
			key: "client_name",
		},
		{
			title: "Job Title",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "Resumes Sent",
			dataIndex: "resumes_sent",
			key: "resumes_sent",
		},
		{
			title: "Resumes Shortlisted",
			dataIndex: "resumes_shortlisted",
			key: "resumes_shortlisted",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
	];

	const expandedRowRender = (record) => {
		return (
			<div className="action-btns">
				<Link to={`/recruiter/job_post_details/${record.id}`}>View JD</Link>
				<Link to={`/recruiter/my_applications_status/${record.id}`}>
					View Applications
				</Link>
				<Link to={`/recruiter/edit_rounds/${record.id}`}>Edit Rounds</Link>
				<Link to={`/recruiter/close_job/${record.id}`}>Close Job</Link>
			</div>
		);
	};

	return (
		<div className="my-tasks-main">
			<h1>My Tasks</h1>
			<Table
				columns={columns}
				dataSource={job}
				rowKey="id"
				pagination={false}
				expandable={{
					expandedRowRender,
				}}
			/>
		</div>
	);
};

export default MyTask;
