import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/context';
import './CompleteJob.css';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import SideNav from './SideNav';
import TopNav from './TopNav';
const apiurl = process.env.REACT_APP_BACKEND_URL;

const CompleteJob = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const { authToken, login } = useContext(AuthContext);
    const [approved, setApproved] = useState(true);
    const [job, setJob] = useState({
        job_id: id,
        job_title: '',
        job_description: '',
        primary_skills: '',
        secondary_skills: '',
        years_of_experience: '',
        ctc: '',
        rounds_of_interview: '',
        interviewers: [],
        interviewer_emails: [],
        job_location: '',
        job_type: '',
        job_level: '',
        qualifications: '',
        timings: '',
        other_benefits: '',
        working_days_per_week: 5,
        interview_process: '',
        decision_maker: '',
        bond: '',
        rotational_shift: false,
        is_assigned: '',
        is_approved: '',
    });

    const [data, setData] = useState('');
    const [feedback, setFeedback] = useState(false);
    const [reject, setReject] = useState(true);
    const [accept, setAccept] = useState(true);
    const [loading, SetLoading] = useState(false);
    const [approveLoading, setApproveLoading] = useState(false);
    useEffect(() => {
        const token = sessionStorage.getItem('authToken');
        if (token) {
            login(token);
        }
    }, [login]);

    useEffect(() => {
        if (authToken) {
            fetch(`${apiurl}/api/particular_job_edit_client/${id}/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    // Safely check and parse interviewers
                    if (typeof data.interviewers === 'string') {
                        data.interviewers = data.interviewers.split(',').map(name => name.trim());
                    }

                    if (typeof data.interviewer_emails === 'string') {
                        data.interviewer_emails = data.interviewer_emails.split(',').map(email => email.trim());
                    }

                    setJob(data);

                    setJob(data);
                    setApproved(data.is_approved);
                })
                .catch(error => console.error('Error fetching job details:', error));
        }
    }, [authToken, id]);

    useEffect(() => {
        if (job.is_assigned) {
            fetch(`${apiurl}/api/get_name/`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: job.is_assigned })
            })
                .then(response => response.json())
                .then(data => setName(data.name))
                .catch(error => console.error('Error fetching assigned user name:', error));
        }
    }, [job.is_assigned, authToken]);

    const handleBack = () => {
        navigate(-1);
    };

    const handleApproved = (e) => {
        setApproveLoading(true);
        e.preventDefault();
        fetch(`${apiurl}/api/client/approve_job/${id}/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                "data": job
            })
        })
            .then(response => response.json())
            .then(data => {
                setApproveLoading(false)
                setMessage(data.success);
                setError(data.error);
                setReject(false);
                setAccept(false);
            })
            .catch(error => console.error('Error approving job:', error));
    };

    const handleFeedback = (e) => {
        setData(e.target.value);
    };

    const toggleReject = () => {
        setFeedback(prev => !prev);
    };

    const handleReject = (e) => {
        SetLoading(true)
        e.preventDefault();
        fetch(`${apiurl}/api/client/reject_job/${id}/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "message": data
            })
        })
            .then(response => response.json())
            .then(data => {
                SetLoading(false)
                setMessage(data.success);
                setError(data.error);
                setAccept(false);
                setReject(false);
                setFeedback(false);
            })
            .catch(error => console.error('Error rejecting job:', error));
    };

    return (
        <div className="no_overlay">
            <TopNav></TopNav>
            <div className='flex'>
                <SideNav></SideNav>

                <div className="job-details" style={{'width':'80vw'}}>
                    {message && <p className='green'>{message}</p>}
                    {error && <p className='red'>{error}</p>}
                    <h1>Job Details for {job.username}</h1>
                    <hr />
                    <p><span>ID:</span> {job.id}</p>
                    <p><span>Posted By:</span> {job.username}</p>
                    <p><span>Job Title:</span> {job.job_title}</p>
                    <p><span>Job Description:</span> {job.job_description}</p>
                    <p><span>Job Department:</span> {job.job_department}</p>
                    <p><span>Primary Skills:</span> {job.primary_skills}</p>
                    <p><span>Secondary Skills:</span> {job.secondary_skills}</p>
                    <p><span>Years of Experience:</span> {job.years_of_experience}</p>
                    <p><span>CTC:</span> {job.ctc}</p>
                    <p><span>Rounds of Interview:</span> {job.rounds_of_interview}</p>
                    {job.interviewers && job.interviewers.map((name, index) => (
                        <p key={index}><span>Interviewer {index + 1}:</span> {name}</p>
                    ))}
                    {job.interviewer_emails && job.interviewer_emails.map((email, index) => (
                        <p key={index}><span>Interviewer Email {index + 1}:</span> {email}</p>
                    ))}
                    <p><span>Job Location:</span> {job.job_location}</p>
                    <p><span>Job Type:</span> {job.job_type}</p>
                    <p><span>Job Level:</span> {job.job_level}</p>
                    <p><span>Qualifications:</span> {job.qualifications}</p>
                    <p><span>Timings:</span> {job.timings}</p>
                    <p><span>Other Benefits:</span> {job.other_benefits}</p>
                    <p><span>Working Days per Week:</span> {job.working_days_per_week}</p>
                    <p><span>Decision Maker:</span> {job.decision_maker}</p>
                    <p><span>Bond:</span> {job.bond}</p>
                    <p><span>Rotational Shift:</span> {job.rotational_shift ? 'Yes' : 'No'}</p>
                    {/* <p><span>Assigned To:</span> {name}</p> */}
                    <p><span>Approval Status:</span> {approved ? 'Approved' : 'Not Approved'}</p>


                    {/* //  <button onClick={handleApproved}>Approve</button> */}
                    <Button type="primary" htmlType="submit" onClick={handleApproved} disabled={message} loading={approveLoading}>
                        Approve
                    </Button>

                    <Button type='primary' disabled={message} danger onClick={toggleReject}>Reject</Button>
                    {/* <button onClick={toggleReject}>Reject</button> */}
                    {feedback &&
                        <form onSubmit={handleReject}>
                            <label htmlFor="Feedback">Give feedback</label>
                            <textarea onChange={handleFeedback} value={data} required />
                            {/* <button type='submit'>Submit</button> */}
                            <Button type="primary" htmlType="submit" disabled={approveLoading} loading={loading}>
                                Submit
                            </Button>
                            <Button onClick={handleBack}><LeftOutlined />Back</Button>
                        </form>
                    }

                </div>
            </div>

        </div>
    );
}

export default CompleteJob;
