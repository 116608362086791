import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Candidate from "./components/dashboard/Candidate/Candidate";
import CandidateApplications from "./components/dashboard/Candidate/MyApplications";
import Client from "./components/dashboard/client/Client";
import Recruiter from "./components/dashboard/Recruiter/Recruiter";
import { LoginForm } from "./components/forms/LoginForm";
import Verify_token from "./components/Verify_token";
import CompleteJobPost from "./components/dashboard/Manager/CompleteJobPost";
import SelectStaff from "./components/dashboard/Manager/SelectStaff";
import CompleteJobPosts from "./components/dashboard/Recruiter/CompleteJobPosts";
import UploadData from "./components/dashboard/Recruiter/UploadData";
import Editjob from "./components/dashboard/Manager/Editjob";
import CompleteJob from "./components/dashboard/client/CompleteJob";
import Resumes from "./components/dashboard/client/Resumes";
import AddRecruiters from "./components/dashboard/Manager/AddRecruiters";
import ViewProfile from "./components/dashboard/profile/Profile_sec";
import EditProfile from "./components/dashboard/profile/EditProfile";
import ParticularApplication from "./components/common/ParticularApplication";
import ChangeStaff from "./components/dashboard/Manager/ChangeStaff";
import ManageEvent from "./components/dashboard/Recruiter/ManageEvents";
import EditRounds from "./components/dashboard/Recruiter/EditRounds";
import EditRounds_Manager from "./components/dashboard/Manager/EditRounds";
import CompleteJobPost_Client from "./components/dashboard/client/CompleteJobPost";
import PromoteCandidates from "./components/dashboard/client/PromoteCandidates";
import CloseJobs from "./components/dashboard/Recruiter/CloseJobs";
import MyResponses from "./components/dashboard/client/MyResponses";
import InterviewersData from "./components/dashboard/client/InterviewersData";
import Forms from "./components/forms/forms";
import ClientSignup from "./components/forms/ClientSignup";
import TermsAndConditions from "./components/dashboard/client/TermsAndConditions";
import SeeNegotiations from "./components/dashboard/Manager/SeeNegotiations";
import CloseParticularJob from "./components/dashboard/Recruiter/CloseParticularJob";
import ForgotPassword1 from "./components/forms/ForgotPassword1";
import SetPassword from "./components/forms/SetPassword";
import MyTask from "./components/dashboard/Recruiter/MyTask";
import MyApplicationsStatus from "./components/dashboard/Recruiter/MyApplicationsStatus";
import CalendarManage from "./components/dashboard/Recruiter/CalendarManage";
import ManageRounds from "./components/dashboard/Recruiter/ManageRounds";
import AnyUpdates from "./components/dashboard/client/AnyUpdates";
import ReceivedData from "./components/dashboard/client/ReceivedData";
import PostJob from "./components/dashboard/client/PostJob";
import ManagerTermsAndConditions from "./components/dashboard/Manager/TermsAndConditions";
import EditRequests from "./components/dashboard/Manager/EditRequests";
import Applications from "./components/dashboard/Manager/Applications";
import Approvals from "./components/dashboard/client/Approvals";
import DetailActivities from "./components/dashboard/Manager/DetailActivities";
import BusinessByClient from "./components/dashboard/Manager/BusinessByClient";
import Replacements from "./components/dashboard/Manager/Replacements";
import RequestReview from "./components/dashboard/Manager/RequestReview";
import RaiseInvoice from "./components/dashboard/Manager/RaiseInvoice";
import Recruiters from "./components/dashboard/Manager/Recruiters";
const App = () => {
	return (
		<Router>
			<div className="App"></div>
			<Routes>
				<Route path="/" exact element={<Forms />} />
				<Route path="/login" element={<LoginForm />} />
				<Route path="/forgot_password" element={<ForgotPassword1 />} />
				<Route path="/set_password/:mail" element={<SetPassword />} />
				<Route path="/verify/" element={<Verify_token />} />
				<Route path="/view_profile/" element={<ViewProfile />} />
				<Route path="/edit_profile/" element={<EditProfile />} />
				<Route path="/application/:id" element={<ParticularApplication />} />
				<Route path="/job_post_details/:id" element={<CompleteJobPost />} />
				<Route path="/select-staff/:id" element={<SelectStaff />} />
				<Route path="/candidate" element={<Candidate />} />
				<Route
					path="/candidate/my_application"
					element={<CandidateApplications />}
				/>
				<Route path="/client" element={<Client />} />
				<Route path="/client/signup" element={<ClientSignup />} />
				<Route path="/client/job_post_details/:id" element={<CompleteJob />} />
				<Route
					path="/client/resumes/particular_resume/:id"
					element={<Resumes />}
				/>
				<Route
					path="/client/complete_job_post/:id"
					element={<CompleteJobPost_Client />}
				/>
				<Route path="/create_events/" element={<ManageEvent />} />
				<Route
					path="/client/promote_candidates/:id"
					element={<PromoteCandidates />}
				/>
				<Route path="/client/my_responses/:id" element={<MyResponses />} />
				<Route
					path="/client/interviewers_data/:id"
					element={<InterviewersData />}
				/>
				<Route path="/client/received_data" element={<ReceivedData />} />
				<Route
					path="/client/new_job_post/terms_and_conditions"
					element={<TermsAndConditions />}
				/>
				<Route
					path="/client/new_job_post/terms_and_conditions/post_job"
					element={<PostJob />}
				/>
				<Route path="client/approvals" element={<AnyUpdates />} />
				<Route
					path="/manager/edit_rounds/:id"
					element={<EditRounds_Manager />}
				/>
				<Route path="/manager/edit_job/:id" element={<Editjob />} />
				<Route path="/manager/add_recruiter/" element={<AddRecruiters />} />
				<Route
					path="/manager/terms_and_conditions/"
					element={<ManagerTermsAndConditions />}
				/>
				<Route
					path="/manager/detail_activities"
					element={<DetailActivities />}
				/>
				<Route path="/manager/edit_requests/" element={<EditRequests />} />
				<Route path="/manager/applications/" element={<Applications />} />
				<Route
					path="/manager/business_by_clients/"
					element={<BusinessByClient />}
				/>
				<Route path="/manager/replacements/" element={<Replacements />} />
				<Route path="/manager/raise_invoice/" element={<RaiseInvoice />} />
				<Route path="/manager/request_review/" element={<RequestReview />} />
				<Route path="/manager/" element={<Recruiters />} />
				<Route path="/manager/change_staff/:id" element={<ChangeStaff />} />
				<Route path="/manager/negotiations/" element={<SeeNegotiations />} />
				<Route path="/recruiter" element={<Recruiter />} />
				<Route
					path="/recruiter/close_job/:id"
					element={<CloseParticularJob />}
				/>
				<Route path="/recruiter/my_tasks" element={<MyTask />} />
				<Route
					path="/recruiter/job_post_details/:id"
					element={<CompleteJobPosts />}
				/>
				<Route path="/recruiter/upload_data/:id" element={<UploadData />} />
				<Route path="/recruiter/edit_rounds/:id" element={<EditRounds />} />
				<Route path="/recruiter/close_job/" element={<CloseJobs />} />
				<Route
					path="/recruiter/my_applications_status/"
					element={<MyApplicationsStatus />}
				/>
				<Route
					path="/recruiter/my_applications_status/:id"
					element={<MyApplicationsStatus />}
				/>
				<Route
					path="/recruiter/manage_calender_events"
					element={<CalendarManage />}
				/>
				<Route path="/recruiter/manage_rounds" element={<ManageRounds />} />
			</Routes>
		</Router>
	);
};

export default App;
