import React, { useState, useContext, useEffect } from 'react';
import { Menu, Layout } from 'antd';
import { AuthContext } from '../../context/context';
import { useNavigate } from 'react-router-dom';
import Profile from '../profile/Profile_sec';
import JobPosts from './JobPosts';
import TermsAndConditions from './TermsAndConditions';
import AddRecruiters from './AddRecruiters';
import Applications from './Applications';
import EditRequests from './EditRequests';

const { Sider, Content } = Layout;

const SideNav = () => {
  const { login, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState('dashboard');
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      login(token);
    }
  }, [login]);

  const handleMenuClick = ({ key }) => {
    setSelectedKey(key);
  };

  const handleOpenChange = keys => {
    setOpenKeys(keys);
  };

  const [addRecruiter] = useState(false);
  const toggleRecruiter = () => {
    navigate('/manager/add_recruiter');
  };

  const [allJobPosts, setAllJobPosts] = useState(false);
  const toggleJobPost = () => {
    navigate('/manager/job_post_details');
    setAllJobPosts(prevState => !prevState);
  };

  const [tandC, setTandC] = useState(false);
  const toggleTandC = () => {
    navigate('/manager/terms_and_conditions');
    setTandC(prevState => !prevState);
  };

  const [profile, setProfile] = useState(false);
  const toggleProfile = () => {
    navigate('/view_profile');
    setProfile(prevState => !prevState);
  };

  const [applications, setApplications] = useState(false);
  const toggleApplications = () => {
    navigate('/manager/applications');
    setApplications(prevState => !prevState);
  };

  const [detailActivities, setDetailActivities] = useState(false);
  const toggleDetailActivities = () => {
    navigate('/manager/detail_activities');
    setDetailActivities(prevState => !prevState);
  };

  const [editRequest, setEditRequest] = useState(false);
  const toggleEditRequests = () => {
    navigate('/manager/edit_requests');
    setEditRequest(prevState => !prevState);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('authToken');
    logout();
    navigate('/');
  };

  return (
    <Sider width={200} className="site-layout-background">
      <Menu
        mode="inline"
        defaultSelectedKeys={['dashboard']}
        selectedKeys={[selectedKey]}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        style={{ height: '100%', borderRight: 0 }}
        onClick={handleMenuClick}
      >
        <Menu.Item key="dashboard" onClick={() => { navigate('/manager'); }} style={{ fontSize: 14 }}>
          <img src="/src/media/dashboard.png" alt="" width={18} height={18} style={{ marginRight: 10, verticalAlign: 'middle' }} />
          Dashboard
        </Menu.Item>
        <Menu.Item key="recruiters" onClick={() => { navigate('/manager/recruiters'); }} style={{ fontSize: 14 }}>
          <img src="/src/media/three_user.png" alt="" width={20} style={{ marginRight: 10, verticalAlign: 'middle' }} />
          Recruiters
        </Menu.Item>
        <Menu.Item key="analytics" onClick={() => { }} style={{ fontSize: 14 }}>
          <img src="/src/media/three_user.png" alt="" width={20} style={{ marginRight: 10, verticalAlign: 'middle' }} />
          Analytics
        </Menu.Item>
        <Menu.Item key="requests" onClick={() => { }} style={{ fontSize: 14 }}>
          <img src="/src/media/three_user.png" alt="" width={20} style={{ marginRight: 10, verticalAlign: 'middle' }} />
          Requests
        </Menu.Item>
        <Menu.SubMenu key="client" title={<span style={{ fontSize: 14 }}><img src="/src/media/three_user.png" alt="" width={20} style={{ marginRight: 10, verticalAlign: 'middle' }} />Client</span>}>
          <Menu.Item key="detailActivities" onClick={toggleDetailActivities} style={{ fontSize: 14 }}>Detail activities</Menu.Item>
          <Menu.Item key="businessByClient" onClick={() => { navigate('/manager/business_by_clients/'); }} style={{ fontSize: 14 }}>Business by client</Menu.Item>
          <Menu.Item key="replacements" onClick={() => { navigate('/manager/replacements/'); }} style={{ fontSize: 14 }}>Replacements</Menu.Item>
          <Menu.Item key="raiseinvoice" onClick={() => { navigate('/manager/raise_invoice'); }} style={{ fontSize: 14 }}>Raise invoice</Menu.Item>
          <Menu.Item key="requestreview" onClick={() => { navigate('/manager/request_review'); }} style={{ fontSize: 14 }}>Request Review</Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key="others" title="Others" style={{ fontSize: 14 }}>
          <Menu.Item key="profile" onClick={toggleProfile} style={{ fontSize: 14 }}>
            View Profile
          </Menu.Item>
          <Menu.Item key="allJobPosts" onClick={toggleJobPost} style={{ fontSize: 14 }}>
            See all Job posts
          </Menu.Item>
          <Menu.Item key="tandC" onClick={toggleTandC} style={{ fontSize: 14 }}>
            Manage Terms and Conditions
          </Menu.Item>
          <Menu.Item key="addRecruiter" onClick={toggleRecruiter} style={{ fontSize: 14 }}>
            Add Recruiters
          </Menu.Item>
          <Menu.Item key="applications" onClick={toggleApplications} style={{ fontSize: 14 }}>
            View All applications
          </Menu.Item>
          <Menu.Item key="editRequests" onClick={toggleEditRequests} style={{ fontSize: 14 }}>
            See my edit requests
          </Menu.Item>
          <Menu.Item key="negotiations" onClick={() => navigate('/manager/negotiations')} style={{ fontSize: 14 }}>
            Negotiations
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="logout" onClick={handleLogout} style={{ fontSize: 14 }}>
          <img src="/src/media/logout.png" alt="" width={20} style={{ marginRight: 10, verticalAlign: 'middle' }} />
          Logout
        </Menu.Item>
      </Menu>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {profile && <Profile onBack={toggleProfile} />}
          {allJobPosts && <JobPosts Toggler={toggleJobPost} />}
          {tandC && <TermsAndConditions Toggler={toggleTandC} />}
          {addRecruiter && <AddRecruiters Toggler={toggleRecruiter} />}
          {applications && <Applications onBack={toggleApplications} />}
          {editRequest && <EditRequests onBack={toggleEditRequests} />}
        </Content>
      </Layout>
    </Sider>
  );
};

export default SideNav;
