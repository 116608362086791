import React, { useState } from "react";
import { LoginForm } from "./LoginForm";
import { SignupForm } from "./signup";
import "./forms.css";
const Forms = () => {
	const [signUp, setSignUp] = useState(false);
	const onToggle = () => {
		setSignUp((e) => !e);
	};
	return (
		<>
			<div className="topnav" style={{ justifyContent: "center" }}>
				<img
					src="src/media/ga_orgsync.png"
					alt="Home page img"
					width={90}
					height={70}
					style={{ marginLeft: 30, marginTop: 5 }}
				/>
			</div>
      <div className="content-section">
			<h1 className="center heading1">
				Connecting professionals and forging <br /> career paths, one connection
				at a time."
			</h1>
			<div className="form_and_img">
				{signUp ? (
					<SignupForm onToggle={onToggle} />
				) : (
					<LoginForm onToggle={onToggle} />
				)}
				<div className="home_img center">
					<img
						src="src/media/home_img.png"
						alt="Home page img"
						className="home-img"
					/>
				</div>
			</div>
      </div>
		</>
	);
};

export default Forms;
