import React, { useEffect, useState } from "react";
import "./TermsAndConditions.css";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import Main from "./Layout";
const apiurl = process.env.REACT_APP_BACKEND_URL;

const TermsAndConditions = () => {
	const authToken = sessionStorage.getItem("authToken");
	const [TandC, setTandC] = useState({ terms_and_conditions: "" });
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleSave = () => {
		setLoading(true);
		fetch(`${apiurl}/api/t_and_c/`, {
			method: "PUT",
			headers: {
				Authorization: `Bearer ${authToken}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				terms_and_conditions: TandC.terms_and_conditions,
			}),
		})
			.then((response) => {
          message.success("Terms and conditions updated successfully");
			})
			.catch((error) => {
				message.error("Failed to update terms and conditions");
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		fetch(`${apiurl}/api/t_and_c/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setTandC(data);
			})
			.catch((error) => console.error("Error fetching job posts:", error));
	}, [authToken]);

	return (
		<Main>
			<div>
				<h1>Your Terms and Conditions</h1>
				<textarea
					className="textarea"
					id="TermsAndConditions"
					value={TandC.terms_and_conditions}
					onChange={(e) => setTandC({ terms_and_conditions: e.target.value })}
					style={{ overflowY: "auto" }}
				/>
				<div className="button-container">
					<Button
						className="button"
						onClick={() => {
							navigate(-1);
						}}>
						Back
					</Button>
					<Button className="button" onClick={handleSave} loading={loading}>
						{loading ? "Saving..." : "Save"}
					</Button>
				</div>
			</div>
		</Main>
	);
};

export default TermsAndConditions;
