import React, { useState, useContext } from 'react';
import { Form, Input, Button, Tooltip } from 'antd';
import { InfoCircleOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import { AuthContext } from '../context/context';
import { useNavigate } from 'react-router-dom';
import './forms.css'
const apiurl = process.env.REACT_APP_BACKEND_URL;

const ClientSignup = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const [error, setError] = useState('');
  const [showSecondPart, setShowSecondPart] = useState(false);
  const [firstPartData, setFirstPartData] = useState({});

  const handleSubmit = async (values) => {
    setLoading(true);
    if (values.password === values.confirm_password) {
      const combinedValues = { ...firstPartData, ...values };
      console.log(values)
      try {
        const response = await fetch(`${apiurl}/api/client_signup/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(combinedValues),
        });
	const userData = await response.json();
        if (!response.ok) {
          	setError(userData.error);
		throw new Error('Failed to submit');
		 setLoading(false);
        }
	setLoading(false);
        login(userData.token);
        sessionStorage.setItem('authToken', userData.token);
        if (userData.is_verified) {
          navigate('/client');
        }
      } catch (error) {
        console.error('Error:', error);
	setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      setError("Passwords didn't match");
      // Handle error display properly
      console.error('Password error:', error);
    }
  };

  const validateUsername = (_, value) => {
    if (!value || /^[a-zA-Z0-9_]+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Username should contain only letters, numbers, and underscores.'));
  };

  const validateEmail = (_, value) => {
    if (!value || /\S+@\S+\.\S+/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid email address.'));
  };

  const handleNext = () => {
    form.validateFields()
      .then(values => {
        setFirstPartData(values);
        setShowSecondPart(true);
      })
      .catch(info => {
        console.log('Validation Failed:', info);
      });
  };

  return (
    <div>
      <div className='topnav'>
        <img src="../src/media/ga_orgsync.png" alt="Home page img" width={90} height={70} style={{ marginLeft: 30, marginTop: 5 }} />
      </div>
      <h1 className='center heading1'>Unlocking Talent, Empowering Companies"</h1>
      
        <div className='client-signup'>
          {error && <p>{error}</p>}
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            style={{ width: 500, margin: '0 auto', display: 'flex', justifyContent: 'space-between' }}
          >
            <div className=''>
              {!showSecondPart && (
                <>

                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, validator: validateUsername }]}
                    validateTrigger="onBlur"
                  >
                    <Input
                      placeholder="Enter your username"
                      prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      suffix={
                        <Tooltip title="Username should contain only letters, numbers, and underscores">
                          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, validator: validateEmail }]}
                    validateTrigger="onBlur"
                  >
                    <Input
                      placeholder="Enter your email"
                      prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Name of Organization"
                    name="name_of_organization"
                    rules={[{ required: true, message: 'Please input the name of your organization!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Designation"
                    name="designation"
                    rules={[{ required: true, message: 'Please input your designation!' }]}
                  >
                    <Input.TextArea rows={1} />
                  </Form.Item>

                  <Form.Item
                    label="Contact Number"
                    name="contact_number"
                    rules={[{ required: true, message: 'Please input your contact number!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Website URL"
                    name="website_url"
                    rules={[{ required: true, message: 'Please input your website URL!' }]}
                  >
                    <Input type="url" />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" onClick={() => navigate(-1)} style={{ backgroundColor: '#001744' }}>
                      Back
                    </Button>
                    <Button type="primary" onClick={handleNext} style={{ backgroundColor: '#001744' }}>
                      Next
                    </Button>
                  </Form.Item>


                </>
              )}

              {showSecondPart && (
                <>
                  
                  <Form.Item
                    label="GST"
                    name="gst"
                    rules={[{ required: true, message: 'Please input your GST number!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Company PAN"
                    name="company_pan"
                    rules={[{ required: true, message: 'Please input your company PAN number!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Company Address"
                    name="company_address"
                    rules={[{ required: true, message: 'Please input your company address!' }]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Enter password' }]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    rules={[{ required: true, message: 'ReEnter password' }]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" onClick={() => setShowSecondPart(false)} style={{ backgroundColor: '#001744' }}>
                      Back
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading} style={{ backgroundColor: '#001744' }}>
                      Signup
                    </Button>
                  </Form.Item>
                </>
              )}
            </div>
          </Form>
          <div className='home_img center' style={{ paddingRight: 100 }}>
            <img src="../src/media/client_signup.png" alt="Client signup " className='home-img' />
          </div>
        </div>
    </div>
  );
};

export default ClientSignup;
