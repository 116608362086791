import React from "react";
import Main from "./Layout";
import MyTask from "./MyTask";
const Recruiter = () => {
	return (
		<Main>
			<MyTask />
		</Main>
	);
};

export default Recruiter;
