import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/context';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { Button } from 'antd';
import styles from './InterviewersData.models.css'
import SideNav from './SideNav';
import TopNav from './TopNav';
const apiurl = process.env.REACT_APP_BACKEND_URL;

const InterviewersData = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [interviewers, setInterviewers] = useState([])
  const { authToken, setAuthToken } = useContext(AuthContext)
  const { login } = useContext(AuthContext)
  const [aloading, setALoading] = useState(false)
  const [rloading, setRLoading] = useState(false)


  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      login(token);
    }
  }, [])

  useEffect(() => {
    console.log("entered here")
    fetch(`${apiurl}/api/particular_interviewers_edited/${id}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        setInterviewers(data.data)
        console.log(data.data, "is the data")
      })
      .catch(error => console.error('Error fetching job posts:', error));
  }, [])

  const handleAccept = () => {
    setALoading(true);
    fetch(`${apiurl}/api/accept_interviewer/${id}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        setALoading(false)
        alert("successfully modified")
        navigate(-1)
      })
      .catch(error => console.error('Error posing interviewer:', error));
  }
  const handleReject = () => {
    setRLoading(true)
    fetch(`${apiurl}/api/reject_interviewer/${id}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        setRLoading(false)
        alert("successfully modified")
        navigate(-1)
      })
      .catch(error => console.error('Error posing interviewer:', error));
  }
  return (
    <div className='no_overflow'>
      <TopNav></TopNav>
      <div className="flex">
        <SideNav></SideNav>
        <div>
          {interviewers && interviewers.length > 0 ? (
            <table className={styles.interviewersTable}>
              <thead>
                <tr>
                  <th>Round</th>
                  <th>Interviewer Name</th>
                  <th>Interviewer Email</th>
                  <th>Mode of Interview</th>
                </tr>
              </thead>
              <tbody>
                {interviewers.map((interviewer, index) => (
                  <tr key={index}>
                    <td>{interviewer.round_num}</td>
                    <td>{interviewer.name}</td>
                    <td>{interviewer.email}</td>
                    <td>{interviewer.type_of_interview}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No interviewers data available</p>
          )}
          <div className="flex">
            <Button loading={aloading} disabled={rloading} style={{ color: 'red' }} onClick={handleAccept}>Accept</Button>
            <Button loading={rloading} disabled={aloading} style={{ color: 'green' }} onClick={handleReject}>Reject</Button>
            {/* <button onClick={handleAccept}>Accept</button>
          <button onClick={handleReject}>Reject</button> */}
          </div>

        </div>

      </div>

    </div>
  )
}

export default InterviewersData
