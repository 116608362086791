import React, { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import './JobPost.css';
import { AuthContext } from '../../context/context';
import { useNavigate } from 'react-router-dom';
import TopNav from './TopNav';
import SideNav from './SideNav';

const apiurl = process.env.REACT_APP_BACKEND_URL;

const RequestReview = ({ Toggler }) => {
    const [jobList, setJobList] = useState([]);
    const navigate = useNavigate();
    const { authToken, login } = useContext(AuthContext);

    useEffect(() => {
        const token = sessionStorage.getItem('authToken');
        if (token) {
            login(token);
        }
    }, [login]);

    useEffect(() => {
        fetchJobPosts();
    }, []);

    const fetchJobPosts = () => {
        fetch(`${apiurl}/api/get_all_job_posts/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setJobList(data.data);
                console.log(data.data, "is the data");
            })
            .catch(error => console.error('Error fetching job posts:', error));
    };

    const handleCompleteDetails = (id) => {
        navigate(`/job_post_details/${id}`);
    };

    const columns = [
        {
            title: 'Client Name',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Role (shared date)',
            dataIndex: 'job_title',
            key: 'job_title'
        },
        {
            title: 'Date of Candidate Joining',
            dataIndex: 'is_assigned',
            key: 'is_assigned'
        },
        {
            title: 'Service Fee',
            dataIndex: '',
            key: 'resumes_shared'
        },
        {
            title: 'Payment Status',
            dataIndex: 'status',
            key: 'status',
        },
        
    ];

    return (
        <div className='no-overflow'>
            <TopNav></TopNav>
            <div className="flex">
                <SideNav></SideNav>
                <div className="table-container">
                    {jobList && jobList.length > 0 ? (
                        <Table columns={columns} dataSource={jobList} rowKey="id" className="custom-table" />
                    ) : (
                        <div className="no-postings">There are no postings</div>
                    )}
                    <div className="back-button-container">
                        <Button onClick={() => {navigate(-1);}}>Back</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestReview;
