import React, { useState, useEffect } from "react";
import { Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import Main from "./Layout";
const apiurl = process.env.REACT_APP_BACKEND_URL;

const SeeNegotiations = () => {
	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const authToken = sessionStorage.getItem("authToken");

	useEffect(() => {
		fetchNegotiations();
	}, [authToken]);

	const fetchNegotiations = () => {
		fetch(`${apiurl}/api/manager/t_and_c/negotiations/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setData(data.data);
			})
			.catch((error) =>
				console.error("Error fetching negotiation requests:", error)
			);
	};

	const handleAccept = () => {};

	const handleReject = () => {};

	const columns = [
		{
			title: "Client Name",
			dataIndex: "username",
			key: "username",
		},
		{
			title: "Negotiation",
			dataIndex: "terms_and_conditions",
			key: "terms_and_conditions",
		},
		{
			title: "Accept or Reject",
			key: "action",
			render: (text, record) => (
				<span>
					<Button
						type="primary"
						style={{ marginRight: 8 }}
						onClick={handleAccept}>
						Accept
					</Button>
					<Button type="danger" onClick={handleReject}>
						Reject
					</Button>
				</span>
			),
		},
	];

	return (
		<Main>
			<div style={{ width: 1350, display: "inline" }}>
				{data && data.length > 0 ? (
					<Table columns={columns} dataSource={data} rowKey="id" />
				) : (
					<p>There are no Negotiations</p>
				)}
			</div>
		</Main>
	);
};

export default SeeNegotiations;
