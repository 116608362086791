import React, { useEffect, useState ,useContext} from 'react'
import { Button } from 'antd';
import { AuthContext } from '../../context/context';
import { Outlet,useNavigate } from 'react-router-dom';

const apiurl = process.env.REACT_APP_BACKEND_URL;

const TopNav = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { authToken } = useContext(AuthContext);
  const [role,SetRole] = useState('');

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      login(token);
    }
  }, [login]);

  useEffect(()=>{
    console.log(authToken)
    fetch(`${apiurl}/api/get_role/`,{
      method:'GET',
      headers : {
        "Authorization":  `Bearer ${authToken}`,
      },

    })
    .then(response=>response.json())
    .then(data =>{
      SetRole(data.role);
      console.log(data.role)
    })
    .catch(error=>{console.error(error)})
  },[authToken])

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#001744' }}>
        <img src="/src/media/ga_orgsync.png" alt="ga_orgsync" width={100} />
        <Button onClick={() => navigate('/view_profile/')} style={{ marginTop: 20 }}>{role}</Button>
      </div>
    </div>
  )
}

export default TopNav
