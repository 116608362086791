import React, { useState, useEffect } from "react";
import { Table, message } from "antd";
import { Link } from "react-router-dom";
import JobStatus from "./JobStatus";
import "./MyJobPosts.css";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const MyJobPosts = () => {
	const [jobList, setJobList] = useState([]);
	const authToken = sessionStorage.getItem("authToken");

	useEffect(() => {
		fetchJobPosts();
	}, []);

	const numberFormat = (value) => {
		let numberString = value.replace(/[^0-9.]/g, "");
		const numericValue = parseFloat(numberString);
		return isNaN(numericValue)
			? ""
			: new Intl.NumberFormat("en-IN", {
					style: "currency",
					currency: "INR",
			  }).format(numericValue);
	};

	const fetchJobPosts = async () => {
		try {
			const response = await fetch(`${apiurl}/api/job_posting/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch job posts");
			}
			const data = await response.json();
			setJobList(data.data);
		} catch (error) {
			message.error("Failed to fetch job posts. Please try again.");
		}
	};

	const columns = [
		{
			title: "Job ID",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Job Title",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "CTC",
			dataIndex: "ctc",
			key: "ctc",
			render: (ctc) => numberFormat(ctc),
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<div className="action-btns">
					<Link to={`/client/complete_job_post/${record.id}`}>
						View Details
					</Link>
					<Link to={`/client/promote_candidates/${record.id}`}>
						View Responses
					</Link>
				</div>
			),
		},
	];

	return (
		<div className="job-postings-list-main">
			<div>
				{jobList && jobList.length > 0 ? (
					<Table
						columns={columns}
						dataSource={jobList}
						rowKey="id"
						expandable={{
							expandedRowRender: (record) => <JobStatus job={record} />,
							rowExpandable: (record) => true,
						}}
						pagination={false}
					/>
				) : (
					<div className="no-postings">There are no postings</div>
				)}
			</div>
		</div>
	);
};

export default MyJobPosts;
