import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/context';
import { Button } from 'antd';
import styles from './CloseJobs.model.css';
const apiurl = process.env.REACT_APP_BACKEND_URL;
const CloseJobs = () => {

  const { login, authToken } = useContext(AuthContext);
  const [loading, Setloading] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    const token = sessionStorage.getItem('authToken')
    if (token) {
      login(token);
    }
  }, [])

  const [data, setData] = useState([])
  useEffect(() => {
    fetch(`${apiurl}/api/recruiter/close_job_page/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then(response => response.json())
      .then(data => {
        setData(data.data)
        console.log(data, "is the data")
      })
      .catch(error => console.error(error))
  }, [authToken])

  const handleClose = (id) => {
    Setloading(true)
    navigate(`/recruiter/close_job/${id}`)
    // fetch(`${apiurl}/api/recruiter/close_particular_job/${id}/`, {
    //   method: 'POST',
    //   headers: {
    //     'Authorization': `Bearer ${authToken}`
    //   },
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     alert(data.success)
    //     console.log(data.success, "is the data")
    //     window.location.reload();
    //   })
    //   .catch(error => console.error(error))
  }

  return (
    <div>
      <h1>close jobs here</h1>
      {data && data.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th}>Job ID</th>
              <th className={styles.th}>Job Title</th>
              <th className={styles.th}>Resume Sent</th>
              <th className={styles.th}>Resume Accepted</th>
              <th className={styles.th}>Resume Rejected</th>
              <th className={styles.th}>Job Status</th>
              <th className={styles.th}>Close Job</th>
            </tr>
          </thead>
          <tbody>
            {data.map((job, index) => (
              <tr key={job.job_id} className={index % 2 === 0 ? styles.trEven : ''}>
                <td className={styles.td}>{job.job_id}</td>
                <td className={styles.td}>{job.job_title}</td>
                <td className={styles.td}>{job.sent}</td>
                <td className={styles.td}>{job.accepted}</td>
                <td className={styles.td}>{job.rejected}</td>
                <td className={styles.td}>{job.status}</td>
                <td className={styles.td}>
                  {job.status === 'opened' && <Button onClick={() => handleClose(job.job_id)} loading={loading}>Close Job</Button>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={styles.noPostings}>There are no postings</div>
      )}

      <button onClick={() => navigate(-1)}>Back</button>
    </div>
  )
}

export default CloseJobs
