import React, {useState, createContext} from "react";

export const AuthContext = createContext();

export const AuthToken = ({children}) => {
    const [authToken, setAuthToken]  = useState(null);

    const login = (token) =>{
        setAuthToken(token);
    }
    
    const logout =(token) =>{
        setAuthToken(null);
    }

    return(
        <AuthContext.Provider value={{ authToken, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}
export default AuthToken