import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./JobPost.css";
import { Link } from "react-router-dom";
import Main from "./Layout";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const JobPosts = () => {
	const [jobList, setJobList] = useState([]);

	const authToken = sessionStorage.getItem("authToken");

	useEffect(() => {
		fetchJobPosts();
	}, []);

	const fetchJobPosts = () => {
		fetch(`${apiurl}/api/get_all_job_posts/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setJobList(data.data);
			})
			.catch((error) => console.error("Error fetching job posts:", error));
	};



	const columns = [
		{
			title: "Client Name",
			dataIndex: "username",
			key: "username",
		},
		{
			title: "Role (shared date)",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "Recruiter Assigned",
			dataIndex: "is_assigned",
			key: "is_assigned",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<Link to={`/job_post_details/${record.id}`}>View complete Details</Link>
			),
		},
	];

	return (
		<Main>
			{jobList && jobList.length > 0 ? (
				<Table
					columns={columns}
					dataSource={jobList}
					rowKey="id"
					className="custom-table"
                    pagination={false}
				/>
			) : (
				<div className="no-postings">There are no postings</div>
			)}
		</Main>
	);
};

export default JobPosts;
