import React, { useState, useContext } from 'react';
import { Form, Input, Button, Tooltip } from 'antd';
import { InfoCircleOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import { AuthContext } from '../context/context';
import { useNavigate } from 'react-router-dom';
import './forms.css'
import { useParams } from 'react-router-dom';
const apiurl = process.env.REACT_APP_BACKEND_URL;
const frontendurl = process.env.REACT_APP_FRONTEND_URL;

const SetPassword = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const [error, setError] = useState('')
    const { mail } = useParams()
    const [loading, setLoading] = useState(false)
    const [success, SetSuccess] = useState('')
    const validateEmail = (_, value) => {
        if (!value || /\S+@\S+\.\S+/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid email address.'));
    };
    const handleSubmit = async (values) => {
        setLoading(true)
        setError('')
        SetSuccess('')
        if (values.password === values.confirm_password) {
            try {
                const response = await fetch(`${apiurl}/api/set_pwd/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                })
                setLoading(false)
                if (!response.ok) {
                    setError("there is an error");
                    SetSuccess('')
                }
                if (response.ok) {
                    const data = await response.json()
                    SetSuccess(data.success);
                    setError('')
                }
            }
            catch (error) {
                console.error(error);
                setError("There is an error from backend");
            }
        }
        else {
            setError("Passwords Didn't Match");
            SetSuccess('')
            setLoading(false)
        }

    };
    return (
        <div className='no_overflow'>
            <div className='topnav'>
                <img src="../src/media/ga_orgsync.png" alt="Home page img" width={90} height={70} style={{ marginLeft: 30, marginTop: 5 }} />
            </div>
            <h1 className='center heading1'>Connecting professionals and forging <br /> career paths, one connection at a time."</h1>
            <div className=' client-signup'>
                <div className="form">
                    {success && <h4 className="green">{success}</h4>}
                    {error && <h4 className="red">{error}</h4>}
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit}
                        style={{ width: 500, margin: '0 auto' }}
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, validator: validateEmail }]}
                            validateTrigger="onBlur"
                            initialValue={mail}
                            
                        >
                            <Input
                                placeholder="Enter your email"
                                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                disabled
                                className="login-input input_width"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Enter password' }]}
                        >
                            <Input.Password className="login-input input_width"/>
                        </Form.Item>

                        <Form.Item
                            label="Confirm Password"
                            name="confirm_password"
                            rules={[{ required: true, message: 'ReEnter password' }]}
                        >
                            <Input.Password className="login-input input_width"/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={() => navigate(`/`)} style={{ backgroundColor: '#001744' }}>
                                Back
                            </Button>
                            <Button type="primary" htmlType="submit" loading={loading} style={{ backgroundColor: '#001744' }}>
                                Save Password
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                <div className='home_img center' style={{ paddingRight: 100 }}>
                    <img src="../src/media/forgot_pwd.png" alt="email verification" className='home-img' />
                </div>
            </div>
        </div>
    )
}

export default SetPassword
