import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/context';
import { Button, Form, Input, Select, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import moment from 'moment';

const apiurl = process.env.REACT_APP_BACKEND_URL;

const splitName = (fullName) => {
    const nameParts = fullName.trim().split(' ');
    let firstName = '', middleName = '', lastName = '';

    if (nameParts.length === 1) {
        firstName = nameParts[0];
    } else if (nameParts.length === 2) {
        [firstName, lastName] = nameParts;
    } else {
        firstName = nameParts[0];
        lastName = nameParts[nameParts.length - 1];
        middleName = nameParts.slice(1, -1).join(' ');
    }

    return { firstName, middleName, lastName };
};

const calculateAge = (dob) => {
    console.log(dob)
    return moment().diff(dob, 'years');
};

const CloseParticularJob = () => {
    const { login, authToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)

    useEffect(() => {
        const token = sessionStorage.getItem('authToken');
        if (token) {
            login(token);
        }
    }, [login]);

    useEffect(() => {
        if (authToken) {
            fetch(`${apiurl}/api/recruiter/close_job/all_accepted_candidates/${id}/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data.data)
                    setData(data.data || []);
                    
                })
                .catch(error => {console.error(error)
                    
                });
        }
    }, [authToken, id]);

    const handleSubmit = async (values) => {
        setLoading(true);
        setSuccess('')
        setError('')
        const dataToSend = values.candidates.map(candidate => ({
            ...candidate,
	    resume : `${candidate.resume}`,
            fullName: `${candidate.first_name} ${candidate.middle_name} ${candidate.last_name}`
        }));
        console.log(dataToSend, "is the data")
        console.log(values.freeze_time, "is the freeze_time")
        try {
            const response = await fetch(`${apiurl}/api/recruiter/close_job/all_accepted_candidates/${id}/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                    // No need to set Content-Type for FormData
                },
                body: JSON.stringify({ candidates: dataToSend, freeze_time: values.freeze_time }) // Ensure 'values' is properly structured for your API
            });

            if (!response.ok) {
                setSuccess('')
                setError("There is an error");
                throw new Error('Failed to submit data');
            }
            form.resetFields()
            const data = await response.json();
            console.log(data);
            setSuccess("Successfully saved")
            setError('')
        } catch (error) {
            setSuccess('')
            setError("There is an error");
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Close Job</h1>

            {error && <p className='red'>{error}</p> }
            {success && <p className='green'>{success}</p> }
            {data && data.length > 0 ? (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    {data.map((candidate, index) => {
                        const { firstName, middleName, lastName } = splitName(candidate.candidate_name);
                        const age = calculateAge(candidate.date_of_birth);

                        return (
                            <div key={index}>
                                <h3>Candidate {index + 1}</h3>
                                <Form.Item
                                    name={['candidates', index, 'first_name']}
                                    label="First Name"
                                    initialValue={firstName}
                                    rules={[{ required: true, message: 'Please enter first name' }]}
                                >
                                    <Input placeholder="Enter candidate's first name"/>
                                </Form.Item>
                                <Form.Item
                                    name={['candidates', index, 'middle_name']}
                                    label="Middle Name"
                                    initialValue={middleName}
                                >
                                    <Input placeholder="Enter candidate's middle name"/>
                                </Form.Item>
                                <Form.Item
                                    name={['candidates', index, 'last_name']}
                                    label="Last Name"
                                    initialValue={lastName}
                                    rules={[{ required: true, message: 'Please enter last name' }]}
                                >
                                    <Input placeholder="Enter candidate's last name"/>
                                </Form.Item>
                                <Form.Item
                                    name={['candidates', index, 'position']}
                                    label="Position"
                                    initialValue={candidate.job_title}
                                    rules={[{ required: true, message: 'Please enter position' }]}
                                >
                                    <Input placeholder="Enter position"/>
                                </Form.Item>
                                <Form.Item
                                    name={['candidates', index, 'age']}
                                    label="Age"
                                    initialValue={age}
                                    rules={[{ required: true, message: 'Please enter age' }]}
                                >
                                    <Input type="number" placeholder="Enter age" readOnly />
                                </Form.Item>
                                <Form.Item
                                    name={['candidates', index, 'gender']}
                                    label="Gender"
                                    initialValue={candidate.gender}
                                    rules={[{ required: true, message: 'Please select gender' }]}
                                >
                                    <Select placeholder="Select gender">
                                        <Select.Option value="male">Male</Select.Option>
                                        <Select.Option value="female">Female</Select.Option>
                                        <Select.Option value="transgender">Transgender</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name={['candidates', index, 'address']}
                                    label="Address"
                                    initialValue={candidate.address}
                                    rules={[{ required: true, message: 'Please enter address' }]}
                                >
                                    <Input.TextArea placeholder="Enter address" />
                                </Form.Item>
                                <Form.Item
                                    name={['candidates', index, 'cover_letter']}
                                    label="Cover Letter"
                                >
                                    <Input.TextArea placeholder="Enter cover letter" />
                                </Form.Item>
                            </div>
                        );
                    })}
                    <Form.Item
                        name={'freeze_time'}
                        label="Freeze Time"
                    >
                        <Input type='number' placeholder="(in months)" />
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={success} type="primary" htmlType="submit" loading={loading}>
                            Submit And Close Job
                        </Button>
                    </Form.Item>
                </Form>
            ) : (
                <p>There are no candidates accepted</p>
            )}
        </div>
    );
};

export default CloseParticularJob;
