import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const TopNav = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#001744' }}>
        <img src="/src/media/ga_orgsync.png" alt="ga_orgsync" width={100} />
        <Button onClick={() => navigate('/manager/profile')} style={{ marginTop: 20 }}>Manager</Button>
      </div>
    </div>
  );
};

export default TopNav;
