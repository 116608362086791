import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/context";
import "./ManageEvent.css";
import Main from "./Layout";
const apiurl = process.env.REACT_APP_BACKEND_URL;
const ManageEvent = () => {
	const [datetime, setDatetime] = useState("");
	const [eventDetails, setEventDetails] = useState("");
	const [selectedRound, setSelectedRound] = useState("");
	const [selectedJobId, setSelectedJobId] = useState("");
	const [selectedCandidate, setSelectedCandidate] = useState("");
	const navigate = useNavigate();
	const [interviewData, setInterviewData] = useState([]);
	const [candidatesData, setCandidatesData] = useState([]);

	const authToken = sessionStorage.getItem("authToken");

	useEffect(() => {
		if (authToken) {
			fetch(`${apiurl}/api/job_details_interview/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					setInterviewData(data.data);
					setCandidatesData(data.candidates_data);
					console.log(data);
				})
				.catch((error) =>
					console.error("Error fetching interview data:", error)
				);
		}
	}, [authToken]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const newEvent = {
			datetime,
			eventDetails,
			selectedRound,
			selectedJobId,
			selectedCandidate,
		};

		try {
			const response = await fetch(`${apiurl}/api/manage_interviews/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${authToken}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(newEvent),
			});

			if (response.ok) {
				const success = await response.json();

				setDatetime("");
				setEventDetails("");
				setSelectedRound("");
				setSelectedJobId("");
				setSelectedCandidate("");
        navigate("/recruiter/manage_calender_events");
			} else {
				console.error("There was an error submitting the form");
			}
		} catch (e) {
			console.error(e);
		}
	};

	const handleBack = () => {
		navigate(-1);
	};

	const renderJobTitleOptions = () => {
		return interviewData.map((interview) => (
			<option key={interview.id} value={interview.id}>
				{`${interview.job_title} (${interview.id})`} {}
			</option>
		));
	};

	const renderRoundOptions = () => {
		const selectedInterview = interviewData.find(
			(interview) => interview.id === parseInt(selectedJobId)
		);
		if (selectedInterview) {
			const rounds = Array.from(
				{ length: selectedInterview.rounds_of_interview },
				(_, i) => i + 1
			);
			return rounds.map((round, index) => (
				<option key={index} value={round}>
					Round {round}
				</option>
			));
		}
		return <option>No Rounds Available</option>;
	};

	const renderCandidateOptions = () => {
		const candidates = candidatesData.filter(
			(candidate) => candidate.job_id === parseInt(selectedJobId)
		);
		return candidates.map((candidate, index) => (
			<option key={index} value={candidate.id}>
				{candidate.candidate_name}
			</option>
		));
	};

	return (
		<Main>
			<div className="manage-events-main">
				<h2>Create Event</h2>
				<form onSubmit={handleSubmit}>
					<div>
						<label htmlFor="jobTitle">Job Title:</label>
						<select
							id="jobTitle"
							value={selectedJobId}
							onChange={(e) => setSelectedJobId(e.target.value)}
							required>
							<option value="">Select Job Title</option>
							{renderJobTitleOptions()}
						</select>
					</div>
					{selectedJobId && (
						<>
							<div>
								<label htmlFor="round">Round:</label>
								<select
									id="round"
									value={selectedRound}
									onChange={(e) => setSelectedRound(e.target.value)}
									required>
									<option value="">Select Round</option>
									{renderRoundOptions()}
								</select>
							</div>
							<div>
								<label htmlFor="candidate">Candidate:</label>
								<select
									id="candidate"
									value={selectedCandidate}
									onChange={(e) => setSelectedCandidate(e.target.value)}
									required>
									<option value="">Select Candidate</option>
									{renderCandidateOptions()}
								</select>
							</div>
						</>
					)}
					<div>
						<label htmlFor="datetime">Date and Time:</label>
						<input
							type="datetime-local"
							id="datetime"
							value={datetime}
							onChange={(e) => setDatetime(e.target.value)}
							required
						/>
					</div>
					<div>
						<label htmlFor="eventDetails">Event Details:</label>
						<input
							type="text"
							id="eventDetails"
							value={eventDetails}
							onChange={(e) => setEventDetails(e.target.value)}
							required
						/>
					</div>
					<button type="submit">Add Event</button>
				</form>
				<button onClick={handleBack}>Back</button>
			</div>
		</Main>
	);
};

export default ManageEvent;
