import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./JobPost.css";
import Main from "./Layout";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const BusinessByClient = () => {
	const [jobList, setJobList] = useState([]);

	const authToken = sessionStorage.getItem("authToken");

	useEffect(() => {
		fetchJobPosts();
	}, []);

	const fetchJobPosts = () => {
		fetch(`${apiurl}/api/get_all_job_posts/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setJobList(data.data);
				console.log(data.data, "is the data");
			})
			.catch((error) => console.error("Error fetching job posts:", error));
	};

	const columns = [
		{
			title: "Client Name",
			dataIndex: "username",
			key: "username",
		},
		{
			title: "Role closed (with date)",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "Payment Status",
			dataIndex: "status",
			key: "status",
		},
	];

	return (
		<Main>
			<div>
				{jobList && jobList.length > 0 ? (
					<Table
						columns={columns}
						dataSource={jobList}
						rowKey="id"
						className="custom-table"
					/>
				) : (
					<div className="no-postings">There are no postings</div>
				)}
			</div>
		</Main>
	);
};

export default BusinessByClient;
