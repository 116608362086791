import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
	DashboardOutlined,
	TeamOutlined,
	BarChartOutlined,
	UnorderedListOutlined,
	UsergroupAddOutlined,
	FileTextOutlined,
	RiseOutlined,
	HomeOutlined,
	EyeOutlined,
	CommentOutlined,
	MailOutlined,
	LogoutOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../context/context";
import logo from "./../../../images/LOGO-10.png";

const { Header, Sider } = Layout;

const apiurl = process.env.REACT_APP_BACKEND_URL;

const Main = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { login, authToken, logout } = useContext(AuthContext);
	const [role, setRole] = useState("");
	const [openKeys, setOpenKeys] = useState([]);

	useEffect(() => {
		const token = sessionStorage.getItem("authToken");
		if (token) {
			login(token);
		}
	}, [login]);

	useEffect(() => {
		fetch(`${apiurl}/api/get_role/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setRole(data.role);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [authToken]);

	const defaultSelectedKeys = () => {
		const pathname = location.pathname;

		const menuItems = [
			"/recruiter",
			"/recruiter/manage_calender_events",
		];

		const index = menuItems.findIndex((item) => item === pathname);
		if (index === -1) return ["1"];
		return [`${index + 1}`];
	};

	const handleMenuItemClick = ({ key, action }) => {
		if (action === "logout") {
			Logout();
		}
		setOpenKeys(openKeys.includes(key) ? [] : [key]);
	};

	const Logout = () => {
		logout();
		sessionStorage.removeItem("authToken");
		navigate("/");
	};

	const menuItems = [
		{
			key: "1",
			icon: <DashboardOutlined />,
			label: <Link to="/recruiter">Dashboard</Link>,
		},
		{
			key: "2",
			icon: <UnorderedListOutlined />,
			label: <Link to="/recruiter/manage_calender_events">My Events</Link>,
		},
		{
			key: "3",
			icon: <LogoutOutlined />,
			label: "Logout",
			onClick: Logout,
		},
	];


	return (
		<Layout>
			<Sider
				className="side"
				breakpoint="lg"
				collapsedWidth="0"
				width={"225px"}
				style={{
					height: "calc(100vh - 100px)",
					position: "fixed",
					left: "0",
					top: "100px",
					bottom: 0,
					zIndex: 1,
					background: "#fff",
				}}>
				<Menu
					theme="light"
					defaultSelectedKeys={defaultSelectedKeys()}
					openKeys={openKeys}
					onOpenChange={(keys) => setOpenKeys(keys)}
					onClick={handleMenuItemClick}
					className="menu"
					mode="inline"
					items={menuItems}></Menu>
			</Sider>

			<Layout>
				<Header className="head" style={{ padding: 0 }}>
					<div className="header-logo">
						<a href="/home">
							<img alt="logo" src={logo}></img>
						</a>
					</div>
					<div className="header-buttons"></div>
				</Header>
				<div className="content">{children}</div>
			</Layout>
		</Layout>
	);
};

export default Main;