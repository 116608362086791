import React, { useContext, useEffect, useState } from "react";
import "./PostJob.css";
import { AuthContext } from "../../context/context";
import { Input, Button, Spin, Steps } from "antd";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import Main from "./Layout";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const PostJob = () => {
	const navigate = useNavigate();
	const { authToken, login } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [jobData, setJobData] = useState({
		job_department: "",
		job_title: "",
		job_type: "",
		job_level: "",
		decision_maker: "",
		decision_maker_email: "",
		primary_skills: "",
		secondary_skills: "",
		years_of_experience: "",
		job_description: "",
		other_benefits: "",
		timings: "",
		working_days_per_week: "",
		rotational_shift: false,
		job_ctc: "",
		bond: "",
		job_location: "",
		rounds_of_interview: 0,
		interviewers: [],
	});
	const [error, setError] = useState("");
	const [title, setTitle] = useState("");
	const [jobTitles, setJobTitles] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [serializerData, setSerializerData] = useState([]);
	const [interviewers, setInterviewers] = useState([]);
	const jobTypes = [
		"Full Time",
		"Part Time",
		"Fresher",
		"Internship",
		"Temporary",
		"Freelance",
		"Volunteer",
	];

	const handleButtonClick = (type) => {
		setJobData((prevData) => ({
			...prevData,
			job_type: type,
		}));
	};

	const handleTitleChange = async (e) => {
		setTitle(e.target.value);
		if (e.target.value.length > 2) {
			try {
				const response = await fetch(
					`${apiurl}/api/job_titles?search=${e.target.value}`,
					{
						method: "GET",
						headers: {
							Authorization: `Bearer ${authToken}`,
						},
					}
				);
				if (response.ok) {
					const data = await response.json();
					const jobTitles = data.data.map((job) => ({
						id: job.id,
						title: job.job_title,
					}));
					setJobTitles(jobTitles);
					setSerializerData(data.data);
					setShowDropdown(true);
				} else {
					setShowDropdown(false);
				}
			} catch (error) {
				setShowDropdown(false);
			}
		} else {
			setShowDropdown(false);
		}
	};

	const handleTitleSelect = (id, title) => {
		setTitle(title);
		const selectedJob = serializerData.find((item) => item.id === id);
		if (selectedJob) {
			setJobData(selectedJob);
		}
		setShowDropdown(false);
	};

	const handleInterviewChange = (e, index) => {
		const { name, value } = e.target;
		const updatedInterviewers = [...interviewers];
		updatedInterviewers[index] = {
			...updatedInterviewers[index],
			[name]: value,
		};
		setInterviewers(updatedInterviewers);
	};

	const numberFormat = (value) => {
		let numberString = value.replace(/[^0-9.]/g, "");
		const numericValue = parseFloat(numberString);
		return isNaN(numericValue)
			? ""
			: new Intl.NumberFormat("en-IN", {
					style: "currency",
					currency: "INR",
			  }).format(numericValue);
	};

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		if (name === "rounds_of_interview") {
			const rounds = parseInt(value, 10) || 0;
			const updatedInterviewers = Array.from(
				{ length: rounds },
				(_, index) => ({
					name: "",
					email: "",
					type_of_interview: "",
					round_num: index + 1,
				})
			);
			setInterviewers(updatedInterviewers);
			setJobData((prevState) => ({
				...prevState,
				rounds_of_interview: rounds,
			}));
		} else {
			setJobData({
				...jobData,
				[name]: type === "checkbox" ? checked : value,
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/api/job_posting/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${authToken}`,
				},
				body: JSON.stringify({
					job_data: jobData,
					interviewers_data: interviewers,
				}),
			});
			if (response.ok) {
				alert("job post added successfully");
				navigate("/client");
			} else {
				const errorData = await response.json();
				setError(errorData.message || "Failed to post job");
			}
		} catch (error) {
			setError("Failed to post job");
		}
		setLoading(false);
	};

	const Page1 = () => {
		return (
			<Steps
				style={{ width: "41vw" }}
				current={0}
				status="error"
				items={[
					{
						title: "In Process",
					},
					{
						title: "Waiting",
					},
					{
						title: "Waiting",
					},
				]}
			/>
		);
	};
	const Page2 = () => {
		return (
			<Steps
				style={{ width: "41vw" }}
				current={1}
				status="error"
				items={[
					{
						title: "Completed",
					},
					{
						title: "InProcess",
					},
					{
						title: "Waiting",
					},
				]}
			/>
		);
	};
	const Page3 = () => {
		return (
			<Steps
				style={{ width: "41vw" }}
				current={2}
				status="error"
				items={[
					{
						title: "Completed",
					},
					{
						title: "Completed",
					},
					{
						title: "InProgress",
					},
				]}
			/>
		);
	};

	const nextPage = () => {
		setPage(page + 1);
	};

	const prevPage = () => {
		setPage(page - 1);
	};

	useEffect(() => {
		const token = sessionStorage.getItem("authToken");
		if (token) {
			login(token);
		}
	}, [login]);

	useEffect(() => {
		setJobData((prevJobData) => ({
			...prevJobData,
			job_title: title,
		}));
	}, [title]);

	return (
		<Main>
			<div className="">
				<div className="job-post-header">
					<h2>Post a Job</h2>
					<div className="search-job-title">
						<Input
							type="text"
							placeholder="Search Job Title"
							value={title}
							onChange={handleTitleChange}
						/>
						{showDropdown && (
							<ul className="dropdown">
								{jobTitles.map((job, index) => (
									<li
										key={index}
										onClick={() => handleTitleSelect(job.id, job.title)}>
										{job.title} (ID: {job.id})
									</li>
								))}
								{jobTitles.length === 0 && <p>There are no jobs</p>}
							</ul>
						)}
					</div>
				</div>
				<form onSubmit={handleSubmit}>
					{page === 1 && (
						<>
							<div>
								<input
									type="text"
									id="job_department"
									className="input_field"
									name="job_department"
									value={jobData.job_department}
									onChange={handleChange}
									placeholder="Job Department"
									required
								/>
							</div>
							<div>
								{}
								<input
									type="text"
									id="job_title"
									name="job_title"
									placeholder="Job Title"
									value={jobData.job_title}
									className="input_field"
									onChange={handleChange}
									required
								/>
							</div>
							<div>
								<label htmlFor="job_type" className="title">
									Job Type:
								</label>
								{}
								<div className="buttonOptions">
									{jobTypes.map((type) => (
										<Button
											key={type}
											onClick={() => handleButtonClick(type)}
											type={jobData.job_type === type ? "primary" : "default"}>
											<PlusOutlined />
											{type}
										</Button>
									))}
								</div>
							</div>
							<div
								className="flex"
								style={{
									justifyContent: "space-between",
									width: "65vw",
								}}>
								<div>
									{}
									<input
										type="text"
										id="job_level"
										name="job_level"
										placeholder="Job Level"
										className="number_field"
										value={jobData.job_level}
										onChange={handleChange}
									/>
								</div>
								<div>
									<input
										type="text"
										name="ctc"
										value={jobData.ctc}
										className="number_field"
										placeholder="CTC"
										onChange={handleChange}
										onBlur={() => {
											setJobData({
												...jobData,
												ctc: numberFormat(jobData.ctc),
											});
										}}
									/>
								</div>
							</div>

							<div>
								<textarea
									id="primary_skills"
									name="primary_skills"
									placeholder="Primary Skills"
									value={jobData.primary_skills}
									onChange={handleChange}
									className="input_field textarea-height"
									rows={4}
									required
								/>
							</div>
							<div>
								<textarea
									id="secondary_skills"
									name="secondary_skills"
									placeholder="Secondary Skills"
									value={jobData.secondary_skills}
									className="input_field textarea-height"
									onChange={handleChange}
									rows={4}
								/>
							</div>
							<div className="pagenum-style">
								<Page1></Page1>
							</div>
							<div className="flex buttons-align">
								<Button danger onClick={() => navigate(-1)}>
									Back
								</Button>
								<Button type="primary" onClick={nextPage}>
									Next
								</Button>
							</div>
						</>
					)}

					{page === 2 && (
						<>
							<div>
								<input
									type="text"
									id="qualifications"
									placeholder="Qualifications"
									name="qualifications"
									className="input_field"
									value={jobData.qualifications}
									onChange={handleChange}
								/>
							</div>
							<div>
								{}
								<input
									type="number"
									id="years_of_experience"
									placeholder="Years of Experience"
									name="years_of_experience"
									value={jobData.years_of_experience}
									onChange={handleChange}
									className="input_field"
									required
								/>
							</div>
							<div>
								{}
								<textarea
									id="job_description"
									name="job_description"
									placeholder="Job Description"
									value={jobData.job_description}
									onChange={handleChange}
									className="input_field textarea-height"
									rows={4}
									required
								/>
							</div>
							<div>
								{}
								<input
									type="text"
									id="other_benefits"
									name="other_benefits"
									placeholder="Other Benifits"
									className="input_field"
									value={jobData.other_benefits}
									onChange={handleChange}
								/>
							</div>
							<div>
								{}
								<input
									type="text"
									id="timings"
									name="timings"
									placeholder="Timings"
									className="input_field"
									value={jobData.timings}
									onChange={handleChange}
								/>
							</div>
							<div>
								{}
								<input
									type="number"
									id="working_days_per_week"
									name="working_days_per_week"
									placeholder="Working days per week"
									className="input_field"
									value={jobData.working_days_per_week}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="shifts">{}</div>
							<div className="pagenum-style">
								<Page2></Page2>
							</div>
							{}
							<div className="navigation-buttons buttons-align">
								<Button type="default" onClick={prevPage}>
									Previous
								</Button>
								<Button type="primary" onClick={nextPage}>
									Next
								</Button>
							</div>
						</>
					)}

					{page === 3 && (
						<>
							<div>
								{}
								<input
									type="text"
									id="job_location"
									name="job_location"
									placeholder="Job Location"
									value={jobData.job_location}
									className="input_field"
									onChange={handleChange}
									required
								/>
							</div>
							<div>
								{}
								<input
									type="text"
									id="bond"
									name="bond"
									placeholder="Bond"
									value={jobData.bond}
									className="input_field"
									onChange={handleChange}
									required
								/>
							</div>
							<div>
								{}
								<input
									type="text"
									id="decision_maker"
									name="decision_maker"
									placeholder="Decision Maker"
									value={jobData.decision_maker}
									className="input_field"
									onChange={handleChange}
									required
								/>
							</div>
							<div>
								{}
								<input
									type="text"
									id="decision_maker_email"
									name="decision_maker_email"
									placeholder="Decision Maker Email"
									value={jobData.decision_maker_email}
									className="input_field"
									onChange={handleChange}
									required
								/>
							</div>
							<div>
								{}
								<input
									type="number"
									id="rounds_of_interview"
									name="rounds_of_interview"
									placeholder="Rounds of Interview"
									className="input_field"
									value={jobData.rounds_of_interview}
									onChange={handleChange}
									required
								/>
							</div>
							<div>
								{jobData.rounds_of_interview &&
									interviewers.map((interviewer, index) => (
										<>
											<div
												style={{
													marginBottom: "10px",
													marginTop: "10px",
												}}>
												<input
													type="text"
													value={`Round ${index + 1}`}
													className="input_field"
												/>
											</div>
											<div className="interviewer-details " key={index}>
												<input
													type="text"
													id={`interviewer_name-${index}`}
													name="name"
													placeholder="interviewer_name"
													className=""
													value={interviewer.name}
													onChange={(e) => handleInterviewChange(e, index)}
													required
												/>
												{}
												<input
													type="text"
													id={`interviewer_email-${index}`}
													placeholder="interviewer_email"
													className=""
													name="email"
													value={interviewer.email}
													onChange={(e) => handleInterviewChange(e, index)}
													required
												/>
												{}
												<select
													name="type_of_interview"
													id={`interview_type-${index}`}
													value={interviewer.type_of_interview}
													onChange={(e) => handleInterviewChange(e, index)}
													required>
													<option value="">Select</option>
													<option value="face_to_face">
														Face To Face Interview
													</option>
													<option value="online">Online Interview</option>
													<option value="telephone">Telephone Interview</option>
												</select>
											</div>
										</>
									))}
							</div>
							<div className="pagenum-style">
								<Page3></Page3>
							</div>
							<div className="navigation-buttons buttons-align">
								<Button type="default" onClick={prevPage}>
									Previous
								</Button>
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
							</div>
						</>
					)}
				</form>

				{error && <div className="error-message">{error}</div>}
				{loading && (
					<div className="loading">
						<Spin />
					</div>
				)}
			</div>
		</Main>
	);
};

export default PostJob;
