import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { AuthContext } from "../../context/context";
import "./SideNav.css";
import {
	MailOutlined,
	LogoutOutlined,
	CalendarOutlined,
	UsergroupAddOutlined,
	AppstoreOutlined,
	PlusCircleOutlined,
	RiseOutlined,
	CommentOutlined,
	EyeOutlined,
	FileTextOutlined,
	HomeOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;

const SideNav = () => {
	const navigate = useNavigate();
	const { logout } = useContext(AuthContext);
	const handleLogout = () => {
		logout();
		sessionStorage.removeItem("authToken");
		navigate("/");
	};

	return (
		<Sider width={200} className="site-layout-background">
			<Menu
				mode="inline"
				defaultSelectedKeys={["dashboard"]}
				style={{ height: "100%", borderRight: 0 }}>
				<Menu.Item
					key="dashboard"
					onClick={() => navigate("/client")}
					style={{ fontSize: 14 }}>
					<AppstoreOutlined style={{ marginRight: "10px" }} /> Dashboard
				</Menu.Item>
				<Menu.Item
					key="addJobPost"
					onClick={() => navigate("/client/new_job_post/terms_and_conditions")}
					style={{ fontSize: 14 }}>
					<PlusCircleOutlined style={{ marginRight: "10px" }} /> Add Job Post
				</Menu.Item>
				<Menu.Item
					key="approvals"
					onClick={() => navigate("/client/approvals")}
					style={{ fontSize: 14 }}>
					<UsergroupAddOutlined style={{ marginRight: "10px" }} /> Approvals
				</Menu.Item>
				<Menu.Item
					key="interviews"
					onClick={() => navigate("/client/interviews")}
					style={{ fontSize: 14 }}>
					<CalendarOutlined style={{ marginRight: "10px" }} /> Interviews
				</Menu.Item>
				<Menu.SubMenu
					key="others"
					title="Others"
					icon={<MailOutlined />}
					style={{ fontSize: 14 }}>
					<Menu.Item
						key="myJobPosts"
						onClick={() => navigate("/client/my_job_posts")}
						style={{ fontSize: 14 }}>
						<HomeOutlined style={{ marginRight: "10px" }} /> See My Jobs
					</Menu.Item>
					<Menu.Item
						key="resume"
						onClick={() => navigate("/client/received_data")}
						style={{ fontSize: 14 }}>
						<FileTextOutlined style={{ marginRight: "10px" }} />
						Received Data
					</Menu.Item>
					<Menu.Item
						key="updates"
						onClick={() => navigate("/client/updates")}
						style={{ fontSize: 14 }}>
						<EyeOutlined style={{ marginRight: "10px" }} /> See Updates by
						manager
					</Menu.Item>
					<Menu.Item
						key="myResponses"
						onClick={() => navigate("/client/my_responses")}
						style={{ fontSize: 14 }}>
						<CommentOutlined style={{ marginRight: "10px" }} /> My Responses
					</Menu.Item>
					<Menu.Item
						key="promoteCandidates"
						onClick={() => navigate("/client/promote_candidates")}
						style={{ fontSize: 14 }}>
						<RiseOutlined style={{ marginRight: "10px" }} /> Promote Candidates
					</Menu.Item>
				</Menu.SubMenu>
				<Menu.Item
					key="logout"
					onClick={() => handleLogout()}
					style={{ fontSize: 14 }}>
					<LogoutOutlined style={{ marginRight: "10px" }} /> Logout
				</Menu.Item>
			</Menu>
		</Sider>
	);
};

export default SideNav;
