import { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { AuthContext } from '../../context/context';
import SideNav from './SideNav';
import TopNav from './TopNav';
import Layout from 'antd/es/layout/layout';
import { Outlet,useNavigate } from 'react-router-dom';
const apiurl = process.env.REACT_APP_BACKEND_URL;

const MyApplications = ({ onBack }) => {
  const { login,authToken } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      login(token);
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      fetch(`${apiurl}/api/candidate/applications/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data.data);
          console.log(data.data);
        })
        .catch((error) =>
          console.error('Error fetching job posts:', error)
        );
    }
  }, [authToken]);

  const columns = [
    {
      title: 'Application ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Job title',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <div className='no_overflow'>
    <TopNav></TopNav>
    <div className="flex">
      <SideNav></SideNav>
      <div>
         {/* <h1>My applications</h1> */}
          <Table dataSource={data} columns={columns} />

          <Button  onClick={() => navigate('/candidate/')}>Back</Button>
      </div>
    </div>

      {/* <div className='no_overflow'>
    <TopNav></TopNav>
    <Layout style={{height:1000}}>
      <SideNav></SideNav>
      <Layout>
      <Content style={{ padding: '0 24px 24px' }}>
                  <Outlet />
              </Content>
      </Layout>
    </Layout>
    <div>
      
     
      <Table dataSource={data} columns={columns} />

      <Button  onClick={() => navigate('/candidate/')}>Back</Button>
    </div>
    </div> */}
    </div>
    
  );
};

export default MyApplications;
