import React, { useContext, useEffect, useState } from "react";
import "./TermsAndConditions.css";
import { AuthContext } from "../../context/context";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import JobPostForm from "./PostJob";
import Main from "./Layout";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const TermsAndConditions = () => {
	const navigate = useNavigate();
	const [error, setError] = useState("");
	const { login } = useContext(AuthContext);
	const { authToken } = useContext(AuthContext);
	const [accepted, setAccepted] = useState(false);
	const [open, setOpen] = useState(false);
	const [TandC, setTandC] = useState(null);
	const [showNegotiate, setShowNegotiate] = useState(false);
	const [negotiationText, setNegotiationText] = useState("");
	const [loading, setLoading] = useState(false);

	const handleBack = () => {
		if (accepted) {
			setOpen((e) => !e);
		}
	};

	const handleAcceptance = () => {
		setAccepted((e) => !e);
	};

	const handleNegotiate = () => {
		setShowNegotiate(true);
	};

	const handleNegotiationSubmit = async () => {
		setLoading(true);
		console.log(negotiationText);
		try {
			const response = await fetch(
				`${apiurl}/api/client/terms_and_conditions/negotiated/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${authToken}`,
					},
					body: JSON.stringify({ negotiationText }),
				}
			);

			if (!response.ok) {
				throw new Error("Failed to submit negotiation");
			}
			const data = await response.json();
		} catch (error) {
			console.error("Error submitting negotiation:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const token = sessionStorage.getItem("authToken");
		if (token) {
			login(token);
		}
	}, [login]);

	useEffect(() => {
		fetch(`${apiurl}/api/t_and_c_for_client/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setTandC(data.data[0]);
				console.log(data.data[0]);
			})
			.catch((error) => console.error("Error fetching job posts:", error));
	}, [authToken]);

	const handleSubmit = () => {
		if (accepted) {
			navigate("/client/new_job_post/terms_and_conditions/post_job");
		} else {
			setError("Accept Terms and conditions");
		}
	};

	return (
		<Main>
			<div className="terms-conditions-main">
				{error && <p className="red">{error}</p>}
				<h2>Terms and Conditions</h2>
				<div className="TandC">
					{TandC && (
						<pre className="tcclient" id="TermsAndConditions">{TandC.terms_and_conditions}</pre>
					)}
				</div>

				<div>
					<label>
						<input
							type="checkbox"
							checked={accepted}
							onChange={handleAcceptance}
						/>
						I accept the terms and conditions.
					</label>
				</div>

				<div className="button_container">
					{open && accepted ? (
						<JobPostForm onBack={handleBack} />
					) : (
						<Button
							type="primary"
							onClick={() => {
								handleSubmit();
							}}>
							Submit
						</Button>
					)}
					<Button danger disabled={accepted} onClick={handleNegotiate}>
						Negotiate with the manager
					</Button>
					<Button type="primary" onClick={() => navigate(-1)}>
						Back
					</Button>
				</div>

				{showNegotiate && (
					<div className="negotiation_container">
						<Input.TextArea
							rows={4}
							value={negotiationText}
							onChange={(e) => setNegotiationText(e.target.value)}
							placeholder="Enter your negotiation text here"
						/>
						<Button
							type="primary"
							onClick={handleNegotiationSubmit}
							loading={loading}>
							Submit Negotiation
						</Button>
					</div>
				)}
			</div>
		</Main>
	);
};

export default TermsAndConditions;
