import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Button, Select, Form } from "antd";
import Main from "./Layout";
import "./ManageEvent.css";

const apiurl = process.env.REACT_APP_BACKEND_URL;
const { Option } = Select;

const MyApplicationsStatus = () => {
	const navigate = useNavigate();
	const authToken = sessionStorage.getItem("authToken");
    const {id} = useParams();
	const [applicationList, setApplicationList] = useState([]);
	const [newList, setNewList] = useState([]);
	const [filter, setFilter] = useState("");
    

	useEffect(() => {
		fetch(`${apiurl}/api/recruiter/applications/${id}`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setApplicationList(data.data);
				setNewList(data.data);
			})
			.catch((error) => console.error(error));
	}, [authToken]);

	const viewApplication = (id) => {
		navigate(`/application/${id}`);
	};

	const handleFilters = () => {
		let filteredList;
		switch (filter) {
			case "reject":
				filteredList = applicationList.filter(
					(application) => application.status === "reject"
				);
				break;
			case "accepted":
				filteredList = applicationList.filter(
					(application) => application.status === "accepted"
				);
				break;
			case "round1":
				filteredList = applicationList.filter(
					(application) => application.status === "round1"
				);
				break;
			case "round2":
				filteredList = applicationList.filter(
					(application) => application.status === "round2"
				);
				break;
			case "round3":
				filteredList = applicationList.filter(
					(application) => application.status === "round3"
				);
				break;
			case "hold":
				filteredList = applicationList.filter(
					(application) => application.status === "hold"
				);
				break;
			case "pending":
				filteredList = applicationList.filter(
					(application) => application.status === "pending"
				);
				break;
			case "all":
				filteredList = applicationList;
				break;
			default:
				filteredList = applicationList;
		}
		setNewList(filteredList);
	};

	const columns = [
		{
			title: "Job Id",
			dataIndex: "job_id",
			key: "job_id",
		},
		{
			title: "Application Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Resume Status",
			dataIndex: "is_viewed",
			key: "is_viewed",
			render: (text) => (text ? "Viewed" : "Not Viewed"),
		},
		{
			title: "Candidate Name",
			dataIndex: "candidate_name",
			key: "candidate_name",
		},
		{
			title: "Application Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Actions",
			key: "view",
			render: (_, record) => (
				<Button
					className="view-button"
					onClick={() => {
						viewApplication(record.id);
					}}>
					View Complete Application
				</Button>
			),
		},
	];

	return (
		<Main>
			<Button className="back-button" onClick={() => navigate(-1)}>
				Back
			</Button>
			<div className="application-status-main">
				<Form className="filter-form" layout="inline" onFinish={handleFilters}>
					<Form.Item>
						<Select
							className="filter-select"
							defaultValue="all"
							onChange={(value) => setFilter(value)}>
							<Option value="all">All</Option>
							<Option value="reject">Rejected</Option>
							<Option value="accepted">Accepted</Option>
							<Option value="round1">Round1 list</Option>
							<Option value="round2">Round2 list</Option>
							<Option value="round3">Round3 list</Option>
							<Option value="hold">On Hold</Option>
							<Option value="pending">Pending list</Option>
						</Select>
					</Form.Item>
					<Form.Item>
						<Button type="primary" className="filter-button" htmlType="submit">
							Filter
						</Button>
					</Form.Item>
				</Form>
				<Table
					className="applications-table"
					dataSource={newList}
					columns={columns}
					rowKey="id"
                    pagination={false}
				/>
			</div>
		</Main>
	);
};

export default MyApplicationsStatus;
