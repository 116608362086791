import React, { useState, useEffect } from "react";
import { Table, Button, message, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import Main from "./Layout";

const apiurl = process.env.REACT_APP_BACKEND_URL;
const { TabPane } = Tabs;

const AnyUpdates = () => {
	const navigate = useNavigate();
	const [jobList, setJobList] = useState([]);
	const [interviewers, setInterviewers] = useState([]);
	const [loading, setLoading] = useState(false);
	const authToken = sessionStorage.getItem("authToken");

	useEffect(() => {
		fetch(`${apiurl}/api/not_approval_jobs/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setJobList(data.job_postings);
				setInterviewers(data.interviewers_data);
			})
			.catch((error) => {
				message.error("Failed to fetch job posts. Please try again.");
			});
	}, [authToken]);

	const handleDetails = (id) => {
		setLoading(true);
		navigate(`/client/job_post_details/${id}`);
	};

	const handleInterviewersData = (id) => {
		navigate(`/client/interviewers_data/${id}`);
	};

	const jobColumns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Job Title",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "Status",
			dataIndex: "edit_status",
			key: "edit_status",
		},
		{
			title: "View Complete Details",
			key: "viewDetails",
			render: (text, record) =>
				record.edit_status === "pending" && (
					<Button
						type="primary"
						onClick={() => handleDetails(record.id)}
						loading={loading}>
						See Complete Details
					</Button>
				),
		},
	];

	const interviewerColumns = [
		{
			title: "ID",
			dataIndex: "job_id",
			key: "job_id",
		},
		{
			title: "Edited By",
			dataIndex: "edited_by",
			key: "edited_by",
		},
		{
			title: "View Complete Details",
			key: "viewInterviewersDetails",
			render: (text, record) => (
				<Button onClick={() => handleInterviewersData(record.job_id)}>
					See Complete details
				</Button>
			),
		},
	];

	return (
		<Main>
			<Tabs defaultActiveKey="1">
				<TabPane tab="Job Posts" key="1">
					<div>
						{jobList && jobList.length > 0 ? (
							<Table columns={jobColumns} dataSource={jobList} rowKey="id" />
						) : (
							<div className="no-postings">There are no changes in postings</div>
						)}
					</div>
				</TabPane>
				<TabPane tab="Interviewers Data" key="2">
					<div>
						{interviewers && interviewers.length > 0 ? (
							<Table
								columns={interviewerColumns}
								dataSource={interviewers}
								rowKey="job_id"
							/>
						) : (
							<div className="no-postings">
								There are no changes in interviewers
							</div>
						)}
					</div>
				</TabPane>
			</Tabs>
		</Main>
	);
};

export default AnyUpdates;
