import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './Verify_token.css'
import {Button} from 'antd'
import { useParams } from 'react-router-dom';

const apiurl = process.env.REACT_APP_BACKEND_URL;
const Verify_token = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [verify, setVerify] = useState(false);
    const [loading,SetLoading] = useState(false);
    // const token = useParams();
    console.log(token, "is the token")
    const handleVerify = async () => {
        SetLoading(true)
        console.log(token, "is the token")
        try {
            const response = await fetch(`${apiurl}/api/verify_email/${token}`);
            SetLoading(false)
            if (response.ok) {
                console.log('Email verified successfully');
                setVerify(e => !e);
                navigate('/');
            } else {
                console.error('Error verifying email:', response.statusText);
            }
        } catch (error) {
            console.error('Error verifying email:', error);
        }
    }


    return (
        <div className='center'>
            {/* <button onClick={handleVerify}>Verify</button> */}
            <Button type='primary' onClick={handleVerify} loading={loading}>Verify</Button>
        </div>
    )
}

export default Verify_token
