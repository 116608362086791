import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./CompleteJobPost.css";
import InterviewersTable from "../../InterviewersTable/InterviewersTable";
import { Button } from "antd";
import Main from "./Layout";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const CompleteJobPost = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [name, setName] = useState("");
	const authToken = sessionStorage.getItem("authToken");
	const [approved, setApproved] = useState(true);
	const [interviewers, SetInterviewers] = useState([]);
	const [job, setJob] = useState({
		id: id,
		username: "",
		job_title: "",
		job_description: "",
		primary_skills: "",
		secondary_skills: "",
		years_of_experience: 0,
		ctc: "",
		rounds_of_interview: 0,
		interviewers: "",
		interviewer_emails: "",
		job_location: "",
		job_type: "",
		job_level: "",
		qualifications: "",
		timings: "",
		other_benefits: "",
		working_days_per_week: 5,
		interview_process: "",
		decision_maker: "",
		bond: "",
		rotational_shift: false,
		is_approved: true,
		is_assigned: null,
	});

	const numberFormat = (value) => {
		let numberString = value;
		const numericValue = parseFloat(numberString);
		return isNaN(numericValue)
			? ""
			: new Intl.NumberFormat("en-IN", {
					style: "currency",
					currency: "INR",
			}).format(numericValue);
	};


	useEffect(() => {
		if (authToken) {
			fetch(`${apiurl}/api/particular_job/${id}/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					SetInterviewers(data.interviewers_data);
					setJob(data.data);
					setApproved(data.is_approved);
				})
				.catch((error) => console.error("Error fetching job details:", error));
		}
	}, [authToken, id]);


	useEffect(() => {
		if (job.is_assigned) {
			fetch(`${apiurl}/api/get_name/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${authToken}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ id: job.is_assigned }),
			})
				.then((response) => response.json())
				.then((data) => setName(data.name))
				.catch((error) =>
					console.error("Error fetching assigned staff name:", error)
				);
		}
	}, [job.is_assigned, authToken]);

	const handleAssign = () => {
		if (job.is_approved) {
			navigate(`/select-staff/${id}`);
		} else {
			alert("This job is not approved yet");
		}
	};

	const handleBack = () => {
		navigate(-1);
	};

	const handleApproved = () => {
		navigate(`/manager/edit_job/${id}`);
	};

	const changeStaff = () => {
		navigate(`/manager/change_staff/${id}`);
	};

	return (
		<Main>
			<Button type="text" onClick={handleBack}>
				{" "}
				&lt;- Go Back
			</Button>
			<div className="job-details-manager-main">
				<h1>Job Details by {job.username}</h1>
				<div className="job-detail">
					<span>Job Title:</span>
					<div>{job.job_title}</div>
				</div>
				<div className="job-detail">
					<span>Job Description:</span>
					<div>{job.job_description}</div>
				</div>
				<div className="job-detail">
					<span>Job Department:</span>
					<div>{job.job_department}</div>
				</div>
				<div className="job-detail">
					<span>Primary Skills:</span>
					<div>{job.primary_skills}</div>
				</div>
				<div className="job-detail">
					<span>Secondary Skills:</span>
					<div>{job.secondary_skills}</div>
				</div>
				<div className="job-detail">
					<span>Years of Experience:</span>
					<div>{job.years_of_experience}</div>
				</div>
				<div className="job-detail">
					<span>CTC:</span>
					<div>{job.ctc}</div>
				</div>
				<div className="job-detail">
					<span>Rounds of Interview:</span>
					<div>{job.rounds_of_interview}</div>
				</div>
				<div className="job-detail">
					<span>Job Location:</span>
					<div>{job.job_location}</div>
				</div>
				<div className="job-detail">
					<span>Job Type:</span>
					<div>{job.job_type}</div>
				</div>
				<div className="job-detail">
					<span>Job Level:</span>
					<div>{job.job_level}</div>
				</div>
				<div className="job-detail">
					<span>Qualifications:</span>
					<div>{job.qualifications}</div>
				</div>
				<div className="job-detail">
					<span>Timings:</span>
					<div>{job.timings}</div>
				</div>
				<div className="job-detail">
					<span>Other Benefits:</span>
					<div>{job.other_benefits}</div>
				</div>
				<div className="job-detail">
					<span>Working Days per Week:</span>
					<div>{job.working_days_per_week}</div>
				</div>
				<div className="job-detail">
					<span>Decision Maker:</span>
					<div>{job.decision_maker}</div>
				</div>
				<div className="job-detail">
					<span>Decision Maker Email:</span>
					<div>{job.decision_maker_email}</div>
				</div>
				<div className="job-detail">
					<span>Bond:</span>
					<div>{job.bond}</div>
				</div>
				<div className="job-detail">
					<span>Rotational Shift:</span>
					<div>{job.rotational_shift ? "Yes" : "No"}</div>
				</div>
				<hr />
				<h2>Interviewers data</h2>
				<div>
					<InterviewersTable interviewers={interviewers} />
				</div>
				<hr />
				{job.status === "opened" && (
					<div>
						{job.is_approved ? (
							<div>
								<h3>Job assigned to..</h3>
								{!job.is_assigned ? (
									<Button onClick={handleAssign}>Assign Job to staff</Button>
								) : (
									<h4 className="green">
										{name}{" "}
										<Button onClick={changeStaff}>Change the staff</Button>
									</h4>
								)}
								{<Button onClick={handleApproved}>Edit Job</Button>}
							</div>
						) : (
							<p className="red">Job changes are not yet accepted</p>
						)}
					</div>
				)}
			</div>
		</Main>
	);
};

export default CompleteJobPost;
