import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/context";
import "./EditRounds.css";
import { Button } from "antd";
const apiurl = process.env.REACT_APP_BACKEND_URL;
const EditRounds = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { authToken, login } = useContext(AuthContext);
	const [interviewers, setInterviewers] = useState([]);
	const [loading, setLoading] = useState(false);

	const [job, setJob] = useState({
		id: id,
		username: "",
		job_title: "",
		job_description: "",
		primary_skills: "",
		secondary_skills: "",
		years_of_experience: 0,
		ctc: "",
		rounds_of_interview: 0,
		job_location: "",
		job_type: "",
		job_level: "",
		qualifications: "",
		timings: "",
		other_benefits: "",
		working_days_per_week: 5,
		decision_maker: "",
		bond: "",
		rotational_shift: false,
		is_approved: true,
		is_assigned: null,
	});

	useEffect(() => {
		const token = sessionStorage.getItem("authToken");
		if (token) {
			login(token);
		}
	}, [login]);

	useEffect(() => {
		if (authToken) {
			fetch(`${apiurl}/api/particular_job_staff/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					setJob(data.data);
					setInterviewers(
						data.interviewers_data.map((interviewer) => ({
							name: interviewer.name,
							email: interviewer.email,
							round_num: interviewer.round_num,
							type_of_interview: interviewer.type_of_interview,
						}))
					);
					console.log(data);
				})
				.catch((error) => console.error("Error fetching job details:", error));
		}
	}, [authToken, id]);

	const handleRoundsChange = (event) => {
		const rounds = parseInt(event.target.value, 10);
		const newInterviewers = [...interviewers];

		if (rounds > newInterviewers.length) {
			for (let i = newInterviewers.length; i < rounds; i++) {
				newInterviewers.push({
					name: "",
					email: "",
					round_num: i + 1,
					type_of_interview: "",
				});
			}
		} else if (rounds < newInterviewers.length) {
			newInterviewers.length = rounds;
		}

		setJob((prevJob) => ({
			...prevJob,
			rounds_of_interview: rounds,
		}));
		setInterviewers(newInterviewers);
	};

	const handleInterviewerChange = (index, field, value) => {
		const newInterviewers = [...interviewers];
		newInterviewers[index][field] = value;
		setInterviewers(newInterviewers);
	};

	const handleSubmit = (e) => {
		setLoading(true);
		e.preventDefault();
		const updatedJob = {
			...job,
			interviewers_data: interviewers,
		};
		console.log(updatedJob);
		fetch(`${apiurl}/api/particular_job_staff/${id}/`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${authToken}`,
			},
			body: JSON.stringify(updatedJob),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => {
				setLoading(false);
				alert("successfull request sent");
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	return (
		<div className="edit-rounds-main">
			<Button type="text" onClick={() => navigate(-1)}> &lt; Back</Button>
			<div>
				<h2>Edit Interview Rounds</h2>
				<form onSubmit={handleSubmit}>
					<p>
						<span>Rounds of Interview:</span>
						<input
							type="number"
							value={job.rounds_of_interview}
							onChange={handleRoundsChange}
							min="0"
						/>
					</p>
					{interviewers.map((interviewer, index) => (
						<div key={index} className="interviewer">
							<p>
								<span>Round {index + 1}:</span>
							</p>
							<p>
								<span>Interviewer Name:</span>
								<input
									type="text"
									value={interviewer.name}
									onChange={(e) =>
										handleInterviewerChange(index, "name", e.target.value)
									}
								/>
							</p>
							<p>
								<span>Interviewer Email:</span>
								<input
									type="email"
									value={interviewer.email}
									onChange={(e) =>
										handleInterviewerChange(index, "email", e.target.value)
									}
								/>
							</p>
							<p>
								<span>Mode of Interview:</span>
								<select
									value={interviewer.type_of_interview}
									onChange={(e) =>
										handleInterviewerChange(
											index,
											"type_of_interview",
											e.target.value
										)
									}
									required>
									<option value="">Select</option>
									<option value="face_to_face">Face To Face Interview</option>
									<option value="online">Online Interview</option>
									<option value="telephone">Telephone Interview</option>
								</select>
							</p>
						</div>
					))}
					{}
					<Button type="primary" htmlType="submit" loading={loading}>
						Submit
					</Button>
				</form>
			</div>
		</div>
	);
};

export default EditRounds;
