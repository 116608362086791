import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./JobPost.css";
import Main from "./Layout";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const DetailActivities = () => {
	const [jobList, setJobList] = useState([]);
	const authToken = sessionStorage.getItem("authToken");

	useEffect(() => {
		fetchJobPosts();
	}, []);

	const fetchJobPosts = () => {
		fetch(`${apiurl}/api/client_activities/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setJobList(data.data);
			})
			.catch((error) => console.error("Error fetching job posts:", error));
	};

	const columns = [
		{
			title: "Client Name",
			dataIndex: "username",
			key: "username",
		},
		{
			title: "Role (shared date)",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "Recruiter Assigned",
			dataIndex: "recruiter_name",
			key: "recruiter_name",
		},
		{
			title: "Started date",
			dataIndex: "created_at",
			key: "created_at",
		},
		{
			title: "No. of resumes shared",
			dataIndex: "candidates",
			key: "candidates",
		},
		{
			title: "No. of resumes shortlisted",
			dataIndex: "candidates_shortlisted",
			key: "candidates_shortlisted",
		},
	];

	return (
		<Main>
			<div>
				{jobList && jobList.length > 0 ? (
					<Table
						columns={columns}
						dataSource={jobList}
						rowKey="id"
						className="custom-table"
					/>
				) : (
					<div className="no-postings">There are no postings</div>
				)}
			</div>
		</Main>
	);
};

export default DetailActivities;
