import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/context'
import { useParams,useNavigate } from 'react-router-dom';
const apiurl = process.env.REACT_APP_BACKEND_URL;
const ChangeStaff = () => {
  const {id} = useParams();
  console.log(id,"is the name")
  const navigate  = useNavigate();
  // console.log(id," is the id")
  const { login, authToken } = useContext(AuthContext);
  const [staff, setStaff] = useState([])
  const [selectedStaff, setSelectedStaff] = useState(null);
  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      login(token);
    }
  }, []);
  console.log(authToken)
  useEffect(() => {
    fetch(`${apiurl}/api/get_all_staff/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(data => setStaff(data))
      .catch((e) => console.log("there is teh error", e))
      // const staffmembers = staff.map(staffMember => staffMember.username);
      //   setStaffNames(staffmembers)
  }, [])

  useEffect(() => {
    console.log(staff); // This will run every time staff is updated
  }, [staff]);
  const handleSelect = async(event) => {
    setSelectedStaff(event.target.value);
    const client = event.target.value
    try{
      const response = await fetch(`${apiurl}/api/select_staf/`,{
        method:'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          client: client,
          id:id,
        })
        
      });
      if (!response.ok) {
        const responseData = await response.json();
        
        if (response.status === 401) {
          throw new Error('Incorrect username or password');
        } else {
          throw new Error(responseData.detail || 'Failed to login');
        }
      }
      if(response.ok){
        const data = await response.json();
        console.log(data,"is the message")
        if(data.success){
          navigate(-1)
        }
      }
    }
    catch(e){
      console.error(e)
    }
  };

  return (
    <div>
      <h2>Select Staff</h2>
      <table>
        <thead>
          <tr>
            <th>Staff Name</th>
            <th>Complete Profile</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          { staff && staff.map((staffMember) => (
            <tr key={staffMember.id}>
              <td>
                {staffMember.username} 
              </td>
              <td>
                <button>
                 View Complete Profile
                </button>
              </td>
              <td>
                <button value={staffMember.username} onClick={handleSelect}>select</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ChangeStaff
