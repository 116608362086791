import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/context';
import { Button } from 'antd';
const apiurl = process.env.REACT_APP_BACKEND_URL;
const ManageRounds = () => {
    const [jobs, setJobs] = useState([]);
    const navigate = useNavigate();
    const { authToken, login } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const token = sessionStorage.getItem('authToken');
        if (token) {
            login(token);
        }
    }, [login]);

    useEffect(() => {
        fetch(`${apiurl}/api/jobposts_for_staff/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${authToken}`
            },
        })
        .then(response => response.json())
        .then(data => {
            setJobs(data.data);
        })
        .catch(error => console.error('Error fetching job posts:', error));
    }, [authToken]);

    const handleEditRounds = (jobId) => {
        setLoading(true);
        navigate(`/recruiter/edit_rounds/${jobId}`); // Navigate to a page to edit rounds for a specific job
    };

    return (
        <div>
            <h2>Manage Rounds</h2>
            <table>
                <thead>
                    <tr>
                        <th>Job ID</th>
                        <th>Job Title</th>
                        <th>Rounds</th>
                        <th>Edit Rounds</th>
                    </tr>
                </thead>
                <tbody>
                    {jobs.map(job => (
                        <tr key={job.id}>
                            <td>{job.id}</td>
                            <td>{job.job_title}</td>
                            <td>{job.rounds_of_interview}</td>
                            <td>
                                {/* <button onClick={() => handleEditRounds(job.id)}>Edit Rounds</button> */}
                                <Button onClick={()=>handleEditRounds(job.id)}>Edit Rounds</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={()=>navigate(-1)}>Go Back</button>
        </div>
    );
};

export default ManageRounds;
