import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/context";
import { useNavigate } from "react-router-dom";
import "./ReceivedData.css";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import Main from "./Layout";
const apiurl = process.env.REACT_APP_BACKEND_URL;
const ReceivedData = () => {
	const [data, setData] = useState("");
	const navigate = useNavigate();
	const { login, authToken } = useContext(AuthContext);
	useEffect(() => {
		const token = sessionStorage.getItem("authToken");
		if (token) {
			login(token);
		}
	}, [login]);
	useEffect(() => {
		fetch(`${apiurl}/api/client/received_data/`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setData(data);

				console.log(data);
			})
			.catch((error) => console.error("Error fetching job posts:", error));
	}, [authToken]);
	const handleResumes = (id) => {
		try {
			navigate(`/client/resumes/particular_resume/${id}`);
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<Main>
			<div className="data-overflow">
				{data && data.length > 0 ? (
					<table>
						<thead>
							<tr>
								<th>Job ID</th>
								<th>Count</th>
								<th>View Resume</th>
							</tr>
						</thead>
						<tbody>
							{data.map((job) => (
								<tr key={job.job_id}>
									<td>{job.job_id}</td>
									<td>{job.job_count}</td>
									<td>
										<button onClick={() => handleResumes(job.job_id)}>
											View Resumes
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<div className="no-applications">There are no applications</div>
				)}
			</div>
		</Main>
	);
};

export default ReceivedData;
