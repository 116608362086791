import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./CalendarManage.css";
import Main from "./Layout";
const apiurl = process.env.REACT_APP_BACKEND_URL;
const EventList = ({ events }) => {
	return (
		<div>
			<h2>Event List</h2>
			<table className="events-table">
				<thead>
					<tr>
						<th>Date</th>
						<th>Event Description</th>
						<th>Job ID</th>
						<th>Round Number</th>
						<th>Recruiter ID</th>
						<th>Resume ID</th>
					</tr>
				</thead>
				<tbody>
					{events.map((event) => (
						<tr key={event.id}>
							<td>{new Date(event.interview_time).toLocaleString()}</td>
							<td>{event.event_description}</td>
							<td>{event.job_id}</td>
							<td>{event.round_num}</td>
							<td>{event.recruiter_id}</td>
							<td>{event.resume_id}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const CalendarManage = () => {
	const navigate = useNavigate();
	const [events, setEvents] = useState([]);
	const authToken = sessionStorage.getItem("authToken");

	useEffect(() => {
		if (authToken) {
			fetchEvents();
		}
	}, [authToken]);

	const fetchEvents = async () => {
		try {
			const response = await fetch(`${apiurl}/api/manage_interviews/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch events");
			}
			const data = await response.json();
			if (data.success && Array.isArray(data.success)) {
				const sortedEvents = data.success.sort(
					(a, b) => new Date(a.interview_time) - new Date(b.interview_time)
				);
				setEvents(sortedEvents);
			} else {
				console.error(
					"Data received from API is not in expected format:",
					data
				);
			}
		} catch (error) {
			console.error("Error fetching events:", error);
		}
	};

	const handleEvents = () => {
		navigate("/create_events/");
	};

	return (
		<Main>
			<div className="calendar-manage-container">
				<EventList events={events} />
				<button className="manage-events-button" onClick={handleEvents}>
					Manage Events
				</button>
			</div>
		</Main>
	);
};

export default CalendarManage;
